<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div class="window-button-container">
    <button class="minimizebutton lower"
        [ngbTooltip]="isMinimized ? 'Click to show all choices and information.' : 'Click to hide finished choices and show compact information.'"
        [ngClass]="{'fancy-button':isMinimized}" (click)="minimize()">
        <i *ngIf="isMinimized" class='bi-arrows-collapse'></i>
        <i *ngIf="!isMinimized" class='bi-arrows-expand'></i>
    </button>
    <button class="tilemodebutton" [ngClass]="{'fancy-button':isTileMode}" [ngbTooltip]="isTileMode ? 'Click to enable list layout' : 'Click to enable tiled layout'"
        (click)="toggleTileMode()">
        <!--Content is css only-->
        <div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
        </div>
    </button>
</div>
<div id="character-height" class="itembox vlist">
    <div *ngIf="!(isMobile && shownList()); else ListCloseButton" class="itembox-close-button"
        [ngbTooltip]="isBlankCharacter() ? 'Create your character first!' : ''">
        <button class="list-item center-aligned" [disabled]="isBlankCharacter()" (click)="toggleCharacterMenu()">
            <header class="sectionHeader">{{closeButtonTitle()}}</header>
        </button>
    </div>
    <ng-template #ListCloseButton>
        <div class="itembox-close-button">
            <button class="list-item center-aligned" (click)="toggleShownList()">
                <header class="sectionHeader">Close</header>
            </button>
        </div>
    </ng-template>
    <div class="charactersheet-column-container">
        <div class="charactersheet-column">
            <div *ngIf="stillLoading" class="loading">Loading</div>
            <!--Management list mode-->
            <ng-container *ngIf="!isTileMode">
                <div class="list-item">
                    <button class="newrow" (click)="onNewCharacter()">
                        New Character
                    </button>
                </div>

                <ng-template #SavegamesInitializingTemplate>
                    <div class="list-item">
                        <button class="newrow loading" disabled>
                            Connecting to database...
                        </button>
                    </div>
                </ng-template>

                <ng-template #CannotLoginTemplate>
                    <div class="list-item">
                        <button class="newrow" (click)="onRetryDatabaseConnection()">
                            Database connection failed - click to retry
                        </button>
                    </div>
                </ng-template>

                <ng-template #NoDatabaseConfiguredTemplate>
                    <div class="list-item">
                        <button class="newrow" disabled>
                            No database configured
                        </button>
                    </div>
                </ng-template>

                <ng-template #NotLoggedInTemplate>
                    <div class="list-item">
                        <button class="newrow" disabled>
                            Not logged in
                        </button>
                    </div>
                </ng-template>

                <ng-template #NoSavegamesTemplate>
                    <div class="list-item">
                        <button class="newrow" (click)="onRetryDatabaseConnection()">
                            Database connection failed - click to retry
                        </button>
                    </div>
                </ng-template>

                <ng-container *ngIf="!areSavegamesInitializing && !isLoggingIn; else SavegamesInitializingTemplate">
                    <ng-container *ngIf="!cannotLogin; else CannotLoginTemplate">
                        <ng-container *ngIf="hasDBConnectionURL; else NoDatabaseConfiguredTemplate">
                            <ng-container *ngIf="isLoggedIn; else NotLoggedInTemplate">
                                <ng-container *ngIf="savegames(); else NoSavegamesTemplate">
                                    <div class="list-item">
                                        <button class="newrow" [ngClass]="{'activechoice':shownList()==='load'}"
                                            (click)="toggleShownList('load')">
                                            Load Character
                                        </button>
                                    </div>
                                    <div *ngIf="!isGMMode && wasCharacterLoadedOrCreated()" class="list-item">
                                        <button class="newrow" [disabled]="isBlankCharacter()"
                                            (click)="saveCharacterToDB()">
                                            Save Character
                                        </button>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <div *ngIf="wasCharacterLoadedOrCreated()" class="list-item">
                    <button class="newrow" [ngClass]="{'activechoice':shownList()==='settings'}"
                        (click)="toggleShownList('settings')">
                        Settings
                    </button>
                </div>

                <div class="list-item">
                    <button class="newrow" [ngClass]="{'activechoice':shownList()==='licenses'}"
                        (click)="toggleShownList('licenses')">
                        Licenses
                    </button>
                </div>

                <div class="list-item">
                    <button class="newrow" [ngClass]="{'activechoice':shownList()==='about'}"
                        (click)="toggleShownList('about')">
                        About
                    </button>
                </div>
            </ng-container>
            <!--Management tile mode-->
            <div *ngIf="isTileMode" class="newrow list-item">
                <div class="icon-list">
                    <button (click)="onNewCharacter()">
                        <app-gridIcon [ngbTooltip]="'New Character'" [subTitle]="'New'"
                            [superTitle]="'icon-bi-file-earmark-person'" />
                    </button>

                    <ng-template #SavegamesInitializingTemplate>
                        <button disabled>
                            <app-gridIcon class="loading" [ngbTooltip]="'Connecting to database...'" [title]="'D B'"
                                [superTitle]="'icon-bi-arrow-repeat'" />
                        </button>
                    </ng-template>

                    <ng-template #CannotLoginTemplate>
                        <button (click)="onRetryLogin()">
                            <app-gridIcon [ngbTooltip]="'Database connection failed - click to retry'"
                                [superTitle]="'icon-bi-arrow-repeat'" />
                        </button>
                    </ng-template>

                    <ng-template #NoDatabaseConfiguredTemplate>
                        <button disabled>
                            <app-gridIcon [ngbTooltip]="'No database configured'"
                                [superTitle]="'icon-bi-file-earmark-x'" />
                        </button>
                    </ng-template>

                    <ng-template #NotLoggedInTemplate>
                        <button disabled>
                            <app-gridIcon [ngbTooltip]="'Not logged in'" [superTitle]="'icon-bi-file-earmark-lock'" />
                        </button>
                    </ng-template>

                    <ng-template #NoSavegamesTemplate>
                        <button (click)="onRetryDatabaseConnection()">
                            <app-gridIcon [ngbTooltip]="'Database connection failed - click to retry'"
                                [superTitle]="'icon-bi-arrow-repeat'" />
                        </button>
                    </ng-template>

                    <ng-container *ngIf="!areSavegamesInitializing && !isLoggingIn; else SavegamesInitializingTemplate">
                        <ng-container *ngIf="!cannotLogin; else CannotLoginTemplate">
                            <ng-container *ngIf="hasDBConnectionURL; else NoDatabaseConfiguredTemplate">
                                <ng-container *ngIf="isLoggedIn; else NotLoggedInTemplate">
                                    <ng-container *ngIf="savegames(); else NoSavegamesTemplate">
                                        <button [ngClass]="{'activechoice':shownList()==='load'}"
                                            (click)="toggleShownList('load')">
                                            <app-gridIcon [ngbTooltip]="'Load Character'" [subTitle]="'Load'"
                                                [superTitle]="'icon-bi-file-earmark-arrow-down'" />
                                        </button>
                                        <button *ngIf="!isGMMode && wasCharacterLoadedOrCreated()"
                                            [disabled]="isBlankCharacter()" (click)="saveCharacterToDB()">
                                            <app-gridIcon
                                                [ngbTooltip]="isBlankCharacter() ? 'No changes to character yet.' : 'Save Character'"
                                                [subTitle]="'Save'" [superTitle]="'icon-bi-file-earmark-arrow-up'" />
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <button *ngIf="wasCharacterLoadedOrCreated()" [ngClass]="{'activechoice':shownList()==='settings'}"
                        (click)="toggleShownList('settings')">
                        <app-gridIcon [ngbTooltip]="'Settings'" [subTitle]="'Settings'" [superTitle]="'icon-bi-gear'" />
                    </button>

                    <div class="filler"></div>

                    <button [ngClass]="{'activechoice':shownList()==='licenses'}" (click)="toggleShownList('licenses')">
                        <app-gridIcon [ngbTooltip]="'Licenses'" [subTitle]="'Licenses'" [superTitle]="'§'" />
                    </button>

                    <button [ngClass]="{'activechoice':shownList()==='about'}" (click)="toggleShownList('about')">
                        <app-gridIcon [ngbTooltip]="'About'" [subTitle]="'About'" [superTitle]="'icon-bi-info-circle'" />
                    </button>
                </div>
            </div>
            <ng-container *ngIf="!stillLoading && characterMenuState === 'in' && wasCharacterLoadedOrCreated()">
                <div class="list-item">
                    <strong>Experience Points</strong>
                    <span class="hlist">
                        <button *ngIf="character.experiencePoints >= 1000 && character.level < 20"
                            (click)="onLevelUp()">
                            Level Up
                        </button>
                        <input type="number" class="number4" id="experiencePoints"
                            [ngbTooltip]="(character.experiencePoints >= 1000 && character.level < 20) ? 'You are ready to level up!' : ''" [ngClass]="{'bonus':(character.experiencePoints >= 1000 && character.level < 20)}"
                            [(ngModel)]="character.experiencePoints"
                            (keypress)="positiveNumbersOnly($event)">
                    </span>
                </div>
                <div *ngFor="let oldLevel of [character.level]; trackBy:trackers.trackByIndex;" class="list-item">
                    <strong>
                        Level
                    </strong>
                    <span>
                        <select [(ngModel)]="character.level" (ngModelChange)="onLevelChange(oldLevel)">
                            <option
                                *ngFor="let level of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]; trackBy:trackers.trackByIndex;"
                                [ngValue]="level">
                                {{level}}
                            </option>
                        </select>
                    </span>
                </div>
                <div class="list-item">
                    <button [ngClass]="{'fancy-button': isLevelFilterShown()}" (click)="toggleLevelFilter()">
                        Filter levels
                    </button>
                    <!--Level selection-->
                    <div class="list-item" [ngbCollapse]="!isLevelFilterShown()">
                        <div class="levelBox fullwidth levelBoxTop">
                            <button class="center-aligned" [ngClass]="{'fancy-button':shownLevel()===0}"
                                (click)="toggleShownLevel(0)">Everything</button>
                            <button class="center-aligned" [ngClass]="{'fancy-button':shownLevel()===-1}"
                                (click)="toggleShownLevel(-1)">History</button>
                        </div>
                        <div class="levelBox fullwidth">
                            <button *ngFor="let levelNumber of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]; trackBy:trackers.trackByIndex;"
                                class="center-aligned"
                                [disabled]="!character.class.name" [ngClass]="{'fancy-button':shownLevel()===levelNumber}"
                                (click)="toggleShownLevel(levelNumber)">{{levelNumber}}</button>
                        </div>
                    </div>
                </div>
                <app-tags [creature]="creatureTypesEnum.Character" [objectName]="'Character'" [showTraits]=true
                    [showFeats]=true [showItems]=true [showActivities]=true [showConditions]=true />
                <div class="vlist" [ngClass]="{'invisible':shownLevel() > 0}">
                    <header class="sectionHeader">History</header>
                    <div class="list-item">
                        <strong>Name</strong>
                        <span>
                            <input type="text" id="charactername" maxLength="100" [(ngModel)]="character.name"
                                (blur)="onNameChange()">
                        </span>
                    </div>
                    <div class="list-item">
                        <div class="newrow">
                            <strong>Party name</strong>
                            <span>
                                <input type="text" id="partyname" maxLength="100" [(ngModel)]="character.partyName">
                            </span>
                        </div>
                        <div *ngIf="savegames()?.length" class="newrow">
                            <span></span>
                            <span>
                                <select [(ngModel)]="character.partyName">
                                    <option *ngFor="let partyName of partyNames(); trackBy:trackers.trackByIndex;"
                                        [ngValue]="partyName">
                                        {{partyName}}
                                    </option>
                                </select>
                            </span>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>
                            Alignment
                        </strong>
                        <span>
                            <select [(ngModel)]="character.alignment" (ngModelChange)="onAlignmentChange()">
                                <option *ngFor="let alignment of alignments(); trackBy:trackers.trackByIndex;"
                                    [ngValue]="alignment">
                                    {{alignment}}
                                </option>
                            </select>
                        </span>
                    </div>
                    <!--Available Ancestry Feats-->
                    <div *ngIf="character.class.ancestry.name" class="list-item fullsize-only">
                        <strong>Available Ancestry Feats</strong>
                        <div *ngIf="character.class.ancestry?.ancestries?.length" class="newrow left-aligned">
                            <cite *ngFor="let ancestry of character.class.ancestry.ancestries; trackBy:trackers.trackByIndex;"
                                class="trait">
                                {{ancestry}}
                            </cite>
                        </div>
                    </div>
                    <!--History list mode-->
                    <ng-container *ngIf="!isTileMode">
                        <!--BaseValues-->
                        <div class="list-item">
                            <button class="newrow fancy-button"
                                [ngClass]="{'choicecleared':character.baseValues.length, 'activechoice':shownList()==='alternativeBaseValues'}"
                                (click)="toggleShownList('alternativeBaseValues')">
                                Alternative Ability Values:
                                {{(character.baseValues.length) ? "Used" : "Not Used"}}
                            </button>
                        </div>
                        <!--Class-->
                        <div class="list-item" [ngClass]="{'fullsize-only':character.class.name}">
                            <button class="newrow"
                                [ngClass]="{'fancy-button choicecleared':character.class.name, 'activechoice':shownList()==='class'}"
                                (click)="toggleShownList('class')">
                                Class{{(character.class.name) ? ": "+ character.class.name : ""}}
                            </button>
                        </div>
                        <!--Ancestry-->
                        <ng-container *ngIf="character.class.name">
                            <div class="list-item list-header"
                                [ngClass]="{'fullsize-only':character.class.ancestry.name}">
                                <button class="newrow"
                                    [ngClass]="{'fancy-button choicecleared':character.class.ancestry.name, 'activechoice':shownList()==='ancestry'}"
                                    (click)="toggleShownList('ancestry')">
                                    Ancestry{{(character.class.ancestry.name) ? ": "+
                                    character.class.ancestry.name : ""}}
                                </button>
                            </div>
                        </ng-container>
                        <!--Heritage-->
                        <ng-container *ngIf="character.class.ancestry.name">
                            <div class="list-item" [ngClass]="{'fullsize-only':character.class.heritage.name}">
                                <button class="newrow"
                                    [ngClass]="{'fancy-button choicecleared':character.class.heritage.name, 'activechoice':shownList()==='heritage'}"
                                    (click)="toggleShownList('heritage')">
                                    Heritage{{(character.class.heritage.name) ? ": "+
                                    character.class.heritage.name : ""}}
                                </button>
                            </div>
                        </ng-container>
                        <!--Languages-->
                        <ng-container
                            *ngFor="let currentLanguages of [availableLanguagesOnLevel(character.level)]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let blankLanguages of [blankLanguagesOnLevel(character.level)]; trackBy:trackers.trackByIndex">
                                <div *ngIf="areLanguagesAvailableOnLevel()" class="list-item"
                                    [ngClass]="{'fullsize-only':!blankLanguages}">
                                    <button class="newrow"
                                        [ngClass]="{'fancy-button choicecleared':!blankLanguages, 'activechoice':shownList()==='languages' && shownContentLevelNumber()===character.level}"
                                        (click)="toggleShownList('languages', character.level)">
                                        Languages
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!--Background-->
                        <ng-container *ngIf="character.class.name">
                            <div class="list-item" [ngClass]="{'fullsize-only':(character.class.background.name)}">
                                <button class="newrow"
                                    [ngClass]="{'fancy-button choicecleared':((character.class.background.name)), 'activechoice':shownList()==='background'}"
                                    (click)="toggleShownList('background')">
                                    Background{{(character.class.background.name) ? ": "+
                                    character.class.background.name : ""}}
                                </button>
                            </div>
                        </ng-container>
                        <!--Deity-->
                        <ng-container *ngIf="character.class.name">
                            <div class="list-item" [ngClass]="{'fullsize-only':character.class.deity}">
                                <button class="newrow"
                                    [ngClass]="{'fancy-button choicecleared':character.class.deity, 'activechoice':shownList()==='deity'}"
                                    (click)="toggleShownList('deity')">
                                    Deity{{character.class.deity ? ": "+character.class.deity : ""}}
                                </button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!--History tile mode-->
                    <div *ngIf="isTileMode" class="list-item icon-list">
                        <!--BaseValues-->
                        <button class="fancy-button"
                            [ngClass]="{'choicecleared':character.baseValues.length, 'activechoice':shownList()==='alternativeBaseValues'}"
                            (click)="toggleShownList('alternativeBaseValues')">
                            <app-gridIcon class="fancy-button"
                                [ngbTooltip]="'Alternative Ability Values: ' + (character.baseValues.length ? 'Used' : 'Not Used')"
                                [title]="'A B I'" [superTitle]="'icon-ra ra-circle-of-circles'" />
                        </button>
                        <!--Class-->
                        <button
                            [ngClass]="{'fancy-button choicecleared fullsize-only':character.class.name, 'activechoice':shownList()==='class'}"
                            (click)="toggleShownList('class')">
                            <app-gridIcon [ngClass]="{'fancy-button':character.class.name}"
                                [ngbTooltip]="'Class' + (character.class.name ? ': ' + character.class.name : '')"
                                [title]="character.class.name ? character.class.name : 'C L A S'"
                                [superTitle]="'icon-ra ra-lightning-sword'" />
                        </button>
                        <!--Ancestry-->
                        <ng-container *ngIf="character.class.name">
                            <button
                                [ngClass]="{'fancy-button choicecleared fullsize-only':character.class.ancestry.name, 'activechoice':shownList()==='ancestry'}"
                                (click)="toggleShownList('ancestry')">
                                <app-gridIcon [ngClass]="{'fancy-button':character.class.ancestry.name}"
                                    [ngbTooltip]="'Ancestry' + (character.class.ancestry.name ? ': ' + character.class.ancestry.name : '')"
                                    [title]="character.class.ancestry.name ? character.class.ancestry.name : 'A N C E'"
                                    [superTitle]="'icon-ra ra-sprout-emblem'" />
                            </button>
                        </ng-container>
                        <!--Heritage-->
                        <ng-container *ngIf="character.class.ancestry.name">
                            <button
                                [ngClass]="{'fancy-button choicecleared fullsize-only':character.class.heritage.name, 'activechoice':shownList()==='heritage'}"
                                (click)="toggleShownList('heritage')">
                                <app-gridIcon [ngClass]="{'fancy-button':character.class.heritage.name}"
                                    [ngbTooltip]="'Heritage' + (character.class.heritage.name ? ': ' + character.class.heritage.name : '')"
                                    [title]="character.class.heritage.name ? character.class.heritage.name : 'H E R I'"
                                    [superTitle]="'icon-ra ra-crowned-heart'" />
                            </button>
                        </ng-container>
                        <!--Languages-->
                        <ng-container
                            *ngFor="let currentLanguages of [availableLanguagesOnLevel(character.level)]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let blankLanguages of [blankLanguagesOnLevel(character.level)]; trackBy:trackers.trackByIndex">
                                <button
                                    *ngIf="areLanguagesAvailableOnLevel()"
                                    [ngClass]="{'fancy-button choicecleared fullsize-only':!blankLanguages, 'activechoice':shownList()==='languages' && shownContentLevelNumber()===character.level}"
                                    (click)="toggleShownList('languages', character.level)">
                                    <app-gridIcon [ngClass]="{'fancy-button':!blankLanguages}"
                                        [ngbTooltip]="'Languages'" [title]="'L A N G'"
                                        [superTitle]="currentLanguages.toString()" />
                                </button>
                            </ng-container>
                        </ng-container>
                        <!--Background-->
                        <ng-container *ngIf="character.class.name">
                            <button
                                [ngClass]="{'fancy-button choicecleared fullsize-only':((character.class.background.name)), 'activechoice':shownList()==='background'}"
                                (click)="toggleShownList('background')">
                                <app-gridIcon [ngClass]="{'fancy-button':character.class.background.name}"
                                    [ngbTooltip]="'Background' + (character.class.background.name ? ': ' + character.class.background.name : '')"
                                    [title]="character.class.background.name ? character.class.background.name : 'B A C K'"
                                    [superTitle]="'icon-ra ra-forging'" />
                            </button>
                        </ng-container>
                        <!--Deity-->
                        <ng-container *ngIf="character.class.name">
                            <button
                                [ngClass]="{'fancy-button choicecleared fullsize-only':character.class.deity, 'activechoice':shownList()==='deity'}"
                                (click)="toggleShownList('deity')">
                                <app-gridIcon [ngClass]="{'fancy-button':character.class.deity}"
                                    [ngbTooltip]="'Deity' + (character.class.deity ? ': ' + character.class.deity : '')"
                                    [title]="character.class.deity ? character.class.deity : 'D E I T'"
                                    [superTitle]="'icon-ra ra-sunbeams'" />
                            </button>
                        </ng-container>
                    </div>
                </div>
                <!--Levels-->
                <ng-container *ngIf="character.class">
                    <ng-container *ngFor="let level of character.class.levels; trackBy:trackers.trackByIndex;">
                        <div *ngIf="level.number > 0"
                            [ngClass]="{'invisible':!(level.number > 0 && [0,level.number].includes(shownLevel())), 'vlist':!isTileMode}">
                            <!--Bonuses templates mode-->
                            <ng-template #AddBonusesTemplate>
                                <header class="subsectionHeader">
                                    Add bonuses
                                </header>
                                <span>
                                    Source
                                    <input type="text" maxLength="30" id="bonusSource_{{level.number}}"
                                        [(ngModel)]="bonusSource">
                                </span>
                                <div class="newrow center-aligned">
                                    <button class="abilityboost center-aligned"
                                        (click)="addBonusAbilityChoice(level, 'Boost')">
                                        Ability<br>Boost
                                    </button>
                                    <button class="abilityboost center-aligned"
                                        (click)="addBonusAbilityChoice(level, 'Flaw')">
                                        Ability<br>Flaw
                                    </button>
                                </div>
                                <div class="newrow center-aligned">
                                    <button class="skillincrease center-aligned"
                                        (click)="addBonusSkillChoice(level, 'Perception')">
                                        Perception<br>Increase
                                    </button>
                                    <button class="skillincrease center-aligned"
                                        (click)="addBonusSkillChoice(level, 'Save')">
                                        Saving&nbsp;Throw<br>Increase
                                    </button>
                                    <button class="skillincrease center-aligned"
                                        (click)="addBonusSkillChoice(level, 'Skill')">
                                        Skill<br>Increase
                                    </button>
                                </div>
                                <div class="newrow center-aligned">
                                    <button class="featchoice center-aligned"
                                        [disabled]="!this.character.class.ancestry.name"
                                        (click)="addBonusFeatChoice(level, 'Ancestry')">
                                        Ancestry<br>Feat
                                    </button>
                                    <button class="featchoice center-aligned"
                                        (click)="addBonusFeatChoice(level, 'Class')">
                                        Class<br>Feat
                                    </button>
                                    <button class="featchoice center-aligned"
                                        (click)="addBonusFeatChoice(level, 'General')">
                                        General<br>Feat
                                    </button>
                                    <button class="featchoice center-aligned"
                                        (click)="addBonusFeatChoice(level, 'Skill')">
                                        Skill<br>Feat
                                    </button>
                                </div>
                                <div class="newrow center-aligned">
                                    <button class="specialchoice center-aligned" (click)="addBonusLoreChoice(level)">
                                        Lore Training
                                    </button>
                                </div>
                            </ng-template>
                            <header *ngIf="level.number > 0" class="sectionHeader levelHeader">
                                <span #AddBonusesPopover="ngbPopover" class="fullsize-only"
                                    triggers="click" [ngbPopover]="AddBonusesTemplate">
                                    <i class='bi-patch-plus'
                                        [ngbTooltip]="!AddBonusesPopover.isOpen() ? 'Add bonus abilities, skills or feats' : ''"></i>
                                </span>
                                Level {{level.number}}
                            </header>
                            <div [ngClass]="{'character-choice-grid':isTileMode, 'vlist':!isTileMode}">
                                <div id="bonuses" [ngClass]="{'icon-list vertical':isTileMode, 'vlist':!isTileMode}">
                                    <ng-container
                                        *ngFor="let fixedAbilityBoosts of [abilityBoostsOnLevel(level.number, '', '', '', '', true)]; trackBy:trackers.trackByIndex;">
                                        <ng-container
                                            *ngFor="let fixedSkillIncreases of [skillIncreasesByLevel(level.number, '', '', '', true)]; trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let fixedFeats of [characterFeatsTakenOnLevel(level.number, 'feat')]; trackBy:trackers.trackByIndex;">
                                                <ng-container
                                                    *ngFor="let fixedFeatures of [characterFeatsTakenOnLevel(level.number, 'feature')]; trackBy:trackers.trackByIndex;">
                                                    <ng-container
                                                        *ngIf="!!(fixedAbilityBoosts.length + fixedSkillIncreases.length + fixedFeatures.length + fixedFeats.length)">
                                                        <!--Fixed information list mode-->
                                                        <div *ngIf="!isTileMode" class="fullsize-only list-item">
                                                            <button class="newrow left-aligned"
                                                                [ngClass]="{'fancy-button':shownFixedChangesLevelNumber()===level.number}"
                                                                (click)="toggleFixedChangesLevelNumber(level.number)">
                                                                <i class="bi-lock-fill"></i>
                                                                Show fixed changes
                                                            </button>
                                                        </div>
                                                        <!--Fixed information tile mode-->
                                                        <div *ngIf="isTileMode" class="fullsize-only">
                                                            <button
                                                                [ngClass]="{'fancy-button activechoice':shownFixedChangesLevelNumber()===level.number}"
                                                                (click)="toggleFixedChangesLevelNumber(level.number)">
                                                                <app-gridIcon
                                                                    [ngClass]="{'fancy-button':shownFixedChangesLevelNumber()===level.number}"
                                                                    [ngbTooltip]="(shownFixedChangesLevelNumber()===level.number ? 'Hide' : 'Show') + ' fixed changes'"
                                                                    [superTitle]="'icon-bi-lock-fill'" />
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <!--Ability boosts-->
                                <div id="abilityboosts"
                                    [ngClass]="{'icon-list vertical':isTileMode, 'vlist':!isTileMode}">
                                    <!--List mode fixed ability boosts-->
                                    <ng-container *ngIf="!isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let boost of abilityBoostsOnLevel(level.number, '', '', '', '', true); trackBy:trackers.trackByIndex;">
                                            <div class="list-item fullsize-only">
                                                <ng-template #FixedAbilityBoostContent>
                                                    <div class="newrow left-aligned">
                                                        <strong>{{boost.type === 'Flaw' ? 'Flaw' :
                                                            'Boost'}}</strong>
                                                        {{boost.name}}
                                                    </div>
                                                    <div class="newrow left-aligned">
                                                        <strong>Granted by</strong>
                                                        {{boost.source}}
                                                    </div>
                                                </ng-template>
                                                <button #FixedAbilityPopover="ngbPopover"
                                                    class="newrow left-aligned fancy-button abilityboost"
                                                    triggers="click" [ngbPopover]="FixedAbilityBoostContent">
                                                    <span
                                                        [ngbTooltip]="'Fixed ' + (boost.type === 'Flaw' ? 'flaw' : 'boost')">
                                                        <i class="bi-lock-fill"></i>
                                                    </span>
                                                    Ability {{boost.type === 'Flaw' ? 'Flaw' : 'Boost'}}: {{boost.name}}
                                                </button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <!--Tile mode fixed ability boosts-->
                                    <ng-container *ngIf="isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let boost of abilityBoostsOnLevel(level.number, '', '', '', '', true); trackBy:trackers.trackByIndex;">
                                            <ng-template #FixedAbilityBoostContent>
                                                <div class="newrow left-aligned">
                                                    <strong>{{boost.type === 'Flaw' ? 'Flaw' :
                                                        'Boost'}}</strong>
                                                    {{boost.name}}
                                                </div>
                                                <div class="newrow left-aligned">
                                                    <strong>Granted by</strong>
                                                    {{boost.source}}
                                                </div>
                                            </ng-template>
                                            <button #FixedAbilityPopover="ngbPopover"
                                                class="fullsize-only inactive-button"
                                                triggers="click" [ngbPopover]="FixedAbilityBoostContent">
                                                <app-gridIcon class="abilityboost"
                                                    [ngbTooltip]="!FixedAbilityPopover.isOpen() && ((boost.type === 'Flaw' ? 'Flaw: ' : 'Boost: ') + boost.name) || undefined"
                                                    [title]="boost.name" [superTitle]="'icon-bi-lock-fill'"
                                                    [detail]="(boost.type === 'Flaw' ? 'Flaw' : 'Boost')" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                        *ngFor="let choice of level.abilityChoices; trackBy:trackers.trackByIndex;">
                                        <ng-container
                                            *ngFor="let maxAvailable of [maxAbilityBoostsAvailableInChoice(choice)]; trackBy:trackers.trackByIndex;">
                                            <!--List mode ability boost buttons-->
                                            <div *ngIf="maxAvailable && !isTileMode" class="list-item"
                                                [ngClass]="{'problem':(choice.boosts.length > maxAvailable || areSomeAbilitiesIllegal(choice, level.number)), 'fullsize-only':choice.boosts.length === maxAvailable}">
                                                <button class="newrow abilityboost"
                                                    [ngClass]="{'fancy-button choicecleared':choice.boosts.length === maxAvailable, 'activechoice':shownList()===choice.id}"
                                                    (click)="toggleShownList(choice.id, level.number, choice)">
                                                    {{abilityChoiceTitle(choice)}}
                                                </button>
                                            </div>
                                            <!--Tile mode ability boost buttons-->
                                            <button *ngIf="maxAvailable && isTileMode" class="abilityboost"
                                                [ngClass]="{'problem':(choice.boosts.length > maxAvailable || areSomeAbilitiesIllegal(choice, level.number)), 'fancy-button fullsize-only choicecleared':choice.boosts.length === maxAvailable, 'activechoice':shownList()===choice.id}"
                                                (click)="toggleShownList(choice.id, level.number, choice)">
                                                <app-gridIcon class="abilityboost"
                                                    [ngbTooltip]="abilityChoiceTitle(choice)" [openDelay]=100
                                                    [superTitle]="maxAvailable.toString()"
                                                    [title]="abilityChoiceIconTitle(maxAvailable, choice)"
                                                    [ngClass]="{'fancy-button':choice.boosts.length === maxAvailable}" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <!--Skill Increases-->
                                <div id="skillincreases"
                                    [ngClass]="{'icon-list vertical':isTileMode, 'vlist':!isTileMode}">
                                    <!--List mode fixed skill increases -->
                                    <ng-container *ngIf="!isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let skillIncrease of skillIncreasesByLevel(level.number, '', '', '', true); trackBy:trackers.trackByIndex;">
                                            <div class="list-item fullsize-only">
                                                <ng-template #FixedSkillIncreaseContent>
                                                    <div class="newrow left-aligned">
                                                        <strong>{{skillIncrease.name}}</strong>
                                                    </div>
                                                    <div class="newrow left-aligned">
                                                        <strong>Granted by</strong>
                                                        {{skillIncrease.source}}
                                                    </div>
                                                </ng-template>
                                                <button #FixedSkillPopover="ngbPopover"
                                                    class="newrow left-aligned fancy-button skillincrease"
                                                    triggers="click" [ngbPopover]="FixedSkillIncreaseContent">
                                                    <span [ngbTooltip]="'Fixed increase'">
                                                        <i class="bi-lock-fill"></i>
                                                    </span>
                                                    Skill Increase: {{skillIncrease.name}}
                                                </button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <!--Tile mode fixed skill increases -->
                                    <ng-container *ngIf="isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let skillIncrease of skillIncreasesByLevel(level.number, '', '', '', true); trackBy:trackers.trackByIndex;">
                                            <ng-template #FixedSkillIncreaseContent>
                                                <div class="newrow left-aligned">
                                                    <strong>{{skillIncrease.name}}</strong>
                                                </div>
                                                <div class="newrow left-aligned">
                                                    <strong>Granted by</strong>
                                                    {{skillIncrease.source}}
                                                </div>
                                            </ng-template>
                                            <button #FixedSkillPopover="ngbPopover"
                                                class="fullsize-only inactive-button" triggers="click"
                                                [ngbPopover]="FixedSkillIncreaseContent">
                                                <app-gridIcon class="skillincrease"
                                                    [ngbTooltip]="!FixedSkillPopover.isOpen() && skillIncrease.name || undefined"
                                                    [title]="skillIncrease.name" [superTitle]="'icon-bi-lock-fill'" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <!--Skill Choices handle tile mode internally-->
                                    <ng-container
                                        *ngFor="let choice of skillChoicesOnLevel(level); let index = index; trackBy:trackers.trackByIndex;">
                                        <app-skillchoice [choice]="choice"
                                            [showChoice]="shownList()" [levelNumber]="level.number" [showContent]="false"
                                            [excludeTemporary]="true" [tileMode]="isTileMode" (showSkillChoiceMessage)="receiveChoiceMessage($event)" />
                                    </ng-container>
                                </div>
                                <!--Feat choices-->
                                <div id="featchoices"
                                    [ngClass]="{'icon-list vertical':isTileMode, 'vlist':!isTileMode}">
                                    <!--List mode fixed feats-->
                                    <ng-container *ngIf="!isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let feat of characterFeatsTakenOnLevel(level.number, 'feat'); trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let libraryFeat of [characterFeatsAndFeatures(feat.name)[0]]; trackBy:trackers.trackByIndex;">
                                                <div class="list-item fullsize-only">
                                                    <ng-template #FixedFeatContent>
                                                        <header class="spellHeader">{{libraryFeat.displayName ||
                                                            feat.name}}
                                                        </header>
                                                        <div class="newrow left-aligned">
                                                            <strong>Granted by</strong>
                                                            {{feat.source}}
                                                        </div>
                                                        <app-feat [feat]="libraryFeat" [levelNumber]="level.number" />
                                                    </ng-template>
                                                    <button #FixedFeatPopover="ngbPopover"
                                                        class="newrow left-aligned fancy-button featchoice" triggers="click"
                                                        [ngbPopover]="FixedFeatContent">
                                                        <span [ngbTooltip]="'Fixed feat'">
                                                            <i class="bi-lock-fill"></i>
                                                        </span>
                                                        {{libraryFeat.displayName || feat.name}}
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Tile mode fixed feats-->
                                    <ng-container *ngIf="isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let feat of characterFeatsTakenOnLevel(level.number, 'feat'); trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let libraryFeat of [characterFeatsAndFeatures(feat.name)[0]]; trackBy:trackers.trackByIndex;">
                                                <ng-template #FixedFeatContent>
                                                    <header class="spellHeader">{{libraryFeat.displayName || feat.name}}
                                                    </header>
                                                    <div class="newrow left-aligned">
                                                        <strong>Granted by</strong>
                                                        {{feat.source}}
                                                    </div>
                                                    <app-feat [feat]="libraryFeat" [levelNumber]="level.number" />
                                                </ng-template>
                                                <button #FixedFeatPopover="ngbPopover"
                                                    class="fullsize-only inactive-button" triggers="click"
                                                    [ngbPopover]="FixedFeatContent">
                                                    <app-gridIcon class="featchoice"
                                                        [ngbTooltip]="!FixedFeatPopover.isOpen() && (libraryFeat.displayName || feat.name) || undefined"
                                                        [title]="(libraryFeat.displayName || feat.name).split(': ')[0]"
                                                        [detail]="(libraryFeat.displayName || feat.name).split(': ')[1] || ''"
                                                        [superTitle]="'icon-bi-lock-fill'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Feat Choices handle tile mode internally-->
                                    <ng-container
                                        *ngFor="let choice of featChoicesOnLevel(level, false); let index = index; trackBy:trackers.trackByIndex;">
                                        <app-featchoice [choice]="choice"
                                            [levelNumber]="level.number" [showContent]="false"
                                            [showFeat]="shownItem()" [showChoice]="shownList()" [unavailableFeats]="character.settings.unavailableFeats"
                                            [lowerLevelFeats]="character.settings.lowerLevelFeats"
                                            [higherLevelFeats]="character.settings.higherLevelFeats"
                                            [archetypeFeats]="character.settings.archetypeFeats"
                                            [tileMode]="isTileMode"
                                            (showFeatChoiceMessage)="receiveChoiceMessage($event)"
                                            (showFeatMessage)="receiveFeatMessage($event)" />
                                    </ng-container>
                                </div>
                                <!--Special choices-->
                                <div id="specialchoices"
                                    [ngClass]="{'icon-list vertical':isTileMode, 'vlist':!isTileMode}">
                                    <!--List mode fixed features-->
                                    <ng-container *ngIf="!isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let feature of characterFeatsTakenOnLevel(level.number, 'feature'); trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let libraryFeature of [characterFeatsAndFeatures(feature.name)[0]]; trackBy:trackers.trackByIndex;">
                                                <div class="list-item fullsize-only">
                                                    <ng-template #FixedFeatureContent>
                                                        <header class="spellHeader">{{libraryFeature.displayName ||
                                                            feature.name}}
                                                        </header>
                                                        <div class="newrow left-aligned">
                                                            <strong>Granted by</strong>
                                                            {{feature.source}}
                                                        </div>
                                                        <app-feat [feat]="libraryFeature" [levelNumber]="level.number" />
                                                    </ng-template>
                                                    <button #FixedFeaturePopover="ngbPopover"
                                                        class="newrow left-aligned fancy-button specialchoice"
                                                        triggers="click" [ngbPopover]="FixedFeatureContent">
                                                        <span [ngbTooltip]="'Fixed ability'">
                                                            <i class="bi-lock-fill"></i>
                                                        </span>
                                                        {{libraryFeature.displayName || feature.name}}
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Tile mode fixed features-->
                                    <ng-container *ngIf="isTileMode && shownFixedChangesLevelNumber()===level.number">
                                        <ng-container
                                            *ngFor="let feature of characterFeatsTakenOnLevel(level.number, 'feature'); trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let libraryFeature of [characterFeatsAndFeatures(feature.name)[0]]; trackBy:trackers.trackByIndex;">
                                                <ng-template #FixedFeatureContent>
                                                    <header class="spellHeader">{{libraryFeature.displayName ||
                                                        feature.name}}
                                                    </header>
                                                    <div class="newrow left-aligned">
                                                        <strong>Granted by</strong>
                                                        {{feature.source}}
                                                    </div>
                                                    <app-feat [feat]="libraryFeature" [levelNumber]="level.number" />
                                                </ng-template>
                                                <button #FixedFeaturePopover="ngbPopover"
                                                    class="fullsize-only inactive-button" triggers="click"
                                                    [ngbPopover]="FixedFeatureContent">
                                                    <app-gridIcon class="specialchoice"
                                                        [ngbTooltip]="!FixedFeaturePopover.isOpen() && (libraryFeature.displayName || feature.name) || undefined"
                                                        [title]="(libraryFeature.displayName || feature.name).split(': ')[0]"
                                                        [detail]="(libraryFeature.displayName || feature.name).split(': ')[1] || ''"
                                                        [superTitle]="'icon-bi-lock-fill'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Languages - show if you have gained languages this level-->
                                    <ng-container
                                        *ngIf="level.number > 0 && areLanguagesAvailableOnLevel(level.number)">
                                        <ng-container
                                            *ngFor="let currentLanguages of [availableLanguagesOnLevel(level.number)]; trackBy:trackers.trackByIndex">
                                            <ng-container
                                                *ngFor="let blankLanguages of [blankLanguagesOnLevel(level.number)]; trackBy:trackers.trackByIndex">
                                                <div *ngIf="!isTileMode" class="list-item"
                                                    [ngClass]="{'fullsize-only':!blankLanguages}">
                                                    <button class="newrow specialchoice"
                                                        [ngClass]="{'fancy-button choicecleared':!blankLanguages, 'activechoice':shownList()==='languages' && shownContentLevelNumber()===level.number}"
                                                        (click)="toggleShownList('languages', level.number)">
                                                        Languages: {{currentLanguages -
                                                        blankLanguages}}/{{currentLanguages}}
                                                    </button>
                                                </div>
                                                <button *ngIf="isTileMode" class="specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared fullsize-only':!blankLanguages, 'activechoice':shownList()==='languages' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('languages', level.number)">
                                                    <app-gridIcon class="specialchoice"
                                                        [ngClass]="{'fancy-button':!blankLanguages}"
                                                        [ngbTooltip]="'Languages: ' + (currentLanguages - blankLanguages) + '/' + currentLanguages"
                                                        [title]="blankLanguages ? blankLanguages.toString() : currentLanguages.toString()"
                                                        [superTitle]="'icon-ra ra-speech-bubbles'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--"Specialchoice" feat choices (feats handle tile mode internally)-->
                                    <ng-container
                                        *ngFor="let choice of featChoicesOnLevel(level, true); let index = index; trackBy:trackers.trackByIndex;">
                                        <app-featchoice [choice]="choice"
                                            [levelNumber]="level.number" [showContent]="false"
                                            [showFeat]="shownItem()" [showChoice]="shownList()" [unavailableFeats]="character.settings.unavailableFeats"
                                            [lowerLevelFeats]="character.settings.lowerLevelFeats"
                                            [higherLevelFeats]="character.settings.higherLevelFeats"
                                            [archetypeFeats]="character.settings.archetypeFeats"
                                            [tileMode]="isTileMode"
                                            (showFeatChoiceMessage)="receiveChoiceMessage($event)"
                                            (showFeatMessage)="receiveFeatMessage($event)" />
                                    </ng-container>
                                    <!--Animal Companion-->
                                    <ng-container *ngIf="hasCompanionBecomeAvailableOnLevel(level.number)">
                                        <ng-container
                                            *ngFor="let companion of [companion]; trackBy:trackers.trackByIndex">
                                            <div *ngIf="!isTileMode" class="list-item"
                                                [ngClass]="{'fullsize-only':(companion.name && companion.species && companion.class.ancestry.name)}">
                                                <button class="newrow specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared':(companion.name && companion.species && companion.class.ancestry.name), 'activechoice':shownList()==='animalcompanion' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('animalcompanion', level.number)">
                                                    Animal Companion{{(companion.class.ancestry.name) ? ": "+
                                                    companion.class.ancestry.name : ""}}
                                                </button>
                                            </div>
                                            <button *ngIf="isTileMode" class="specialchoice"
                                                [ngClass]="{'fancy-button choicecleared fullsize-only':(companion.name && companion.species && companion.class.ancestry.name), 'activechoice':shownList()==='animalcompanion' && shownContentLevelNumber()===level.number}"
                                                (click)="toggleShownList('animalcompanion', level.number)">
                                                <app-gridIcon class="specialchoice"
                                                    [ngClass]="{'fancy-button':(companion.name && companion.species && companion.class.ancestry.name)}"
                                                    [ngbTooltip]="'Animal Companion' + ((companion.class.ancestry.name) ? ': ' + companion.class.ancestry.name : '')"
                                                    [title]="companion.class.ancestry.name || ''"
                                                    [superTitle]="'icon-ra ra-wolf-howl'" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <!--Animal Specializations-->
                                    <ng-container *ngIf="companionSpecializationsAvailable(level.number)">
                                        <ng-container
                                            *ngFor="let available of [companionSpecializationsAvailable(level.number)]; trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let takenSpecializations of [companionSpecializationsOnLevel(level.number)]; trackBy:trackers.trackByIndex">
                                                <div *ngIf="!isTileMode" class="list-item"
                                                    [ngClass]="{'fullsize-only':takenSpecializations.length === available}">
                                                    <button class="newrow specialchoice"
                                                        [ngClass]="{'fancy-button choicecleared':(takenSpecializations.length === available), 'activechoice':shownList()==='companionspecialization' && shownContentLevelNumber()===level.number}"
                                                        (click)="toggleShownList('companionspecialization', level.number)">
                                                        {{companionSpecializationChoiceTitle(available,
                                                        takenSpecializations)}}
                                                    </button>
                                                </div>
                                                <button *ngIf="isTileMode" class="specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared fullsize-only':(takenSpecializations.length === available), 'activechoice':shownList()==='companionspecialization' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('companionspecialization', level.number)">
                                                    <app-gridIcon class="specialchoice"
                                                        [ngClass]="{'fancy-button':(takenSpecializations.length === available)}"
                                                        [ngbTooltip]="'Animal Companion Specialization' + (available > 1 ? ': ' + takenSpecializations.length + '/' + available : (takenSpecializations.length === 1 ? ': ' + takenSpecializations[0] : ''))"
                                                        [title]="(available > 1) ? takenSpecializations.length.toString() + '/' + available.toString() : (takenSpecializations.length ? takenSpecializations[0] : '')"
                                                        [superTitle]="'icon-ra ra-love-howl'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Familiar-->
                                    <ng-container *ngIf="isFamiliarAvailableOnLevel(level.number)">
                                        <ng-container
                                            *ngFor="let finished of [!!familiar.name && !!familiar.species]; trackBy:trackers.trackByIndex;">
                                            <div *ngIf="!isTileMode" class="list-item"
                                                [ngClass]="{'fullsize-only':finished}">
                                                <button class="newrow specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared':finished, 'activechoice':shownList()==='familiar' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('familiar', level.number)">
                                                    Familiar
                                                </button>
                                            </div>
                                            <button *ngIf="isTileMode" class="specialchoice"
                                                [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='familiar' && shownContentLevelNumber()===level.number}"
                                                (click)="toggleShownList('familiar', level.number)">
                                                <app-gridIcon class="specialchoice"
                                                    [ngClass]="{'fancy-button':finished}" [ngbTooltip]="'Familiar'"
                                                    [superTitle]="'icon-ra ra-raven'" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <!--Different Worlds-->
                                    <ng-container
                                        *ngFor="let differentWorldsData of differentWorldsData(level.number); trackBy:trackers.trackByIndex;">
                                        <ng-container
                                            *ngFor="let finished of [!!differentWorldsData.getValue('name') && !!differentWorldsData.getValue('background')]; trackBy:trackers.trackByIndex;">
                                            <!--Different Worlds-->
                                            <div *ngIf="!isTileMode" class="list-item"
                                                [ngClass]="{'fullsize-only':finished}">
                                                <button class="newrow specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared':finished, 'activechoice':shownList()==='differentworlds' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('differentworlds', level.number)">
                                                    Different Worlds{{(differentWorldsData.getValue('background')) ? ":
                                                    " +
                                                    differentWorldsData.getValue('background') : ""}}
                                                </button>
                                            </div>
                                            <button *ngIf="isTileMode" class="specialchoice"
                                                [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='differentworlds' && shownContentLevelNumber()===level.number}"
                                                (click)="toggleShownList('differentworlds', level.number)">
                                                <app-gridIcon class="specialchoice"
                                                    [ngClass]="{'fancy-button':finished}"
                                                    [ngbTooltip]="'Different Worlds' + (differentWorldsData.getValue('background') ? ': ' + differentWorldsData.getValue('background') : '')"
                                                    [title]="differentWorldsData.valueAsString('background') || ''"
                                                    [superTitle]="'icon-ra ra-arcane-mask'" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <!--Blessed Blood-->
                                    <ng-container *ngIf="isBlessedBloodAvailable(level.number)">
                                        <ng-container
                                            *ngFor="let blessedBloodSpells of [blessedBloodSpellsTaken()]; trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let finished of [blessedBloodSpells >= 3]; trackBy:trackers.trackByIndex;">
                                                <div *ngIf="!isTileMode" class="list-item"
                                                    [ngClass]="{'fullsize-only':finished}">
                                                    <button class="newrow specialchoice"
                                                        [ngClass]="{'fancy-button choicecleared':finished, 'activechoice':shownList()==='blessedblood' && shownContentLevelNumber()===level.number}"
                                                        (click)="toggleShownList('blessedblood', level.number)">
                                                        Blessed Blood Deity Spells: {{blessedBloodSpells}}/3
                                                    </button>
                                                </div>
                                                <button *ngIf="isTileMode" class="specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='blessedblood' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('blessedblood', level.number)">
                                                    <app-gridIcon class="specialchoice"
                                                        [ngClass]="{'fancy-button':finished}"
                                                        [ngbTooltip]="'Blessed Blood Deity Spells: ' + blessedBloodSpells.toString() + '/3'"
                                                        [title]="finished ? '3' : blessedBloodSpells.toString() + '/3'"
                                                        [superTitle]="'icon-ra ra-burning-eye'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Splinter Faith-->
                                    <ng-container *ngIf="isSplinterFaithAvailable(level.number)">
                                        <ng-container
                                            *ngFor="let splinterFaithDomains of [splinterFaithDomains().length || 0]; trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let finished of [splinterFaithDomains >= 4]; trackBy:trackers.trackByIndex;">
                                                <div *ngIf="!isTileMode" class="list-item"
                                                    [ngClass]="{'fullsize-only':finished}">
                                                    <button class="newrow specialchoice"
                                                        [ngClass]="{'fancy-button choicecleared':finished, 'activechoice':shownList()==='splinterfaith' && shownContentLevelNumber()===level.number}"
                                                        (click)="toggleShownList('splinterfaith', level.number)">
                                                        Splinter Faith Domains: {{splinterFaithDomains}}/4
                                                    </button>
                                                </div>
                                                <button *ngIf="isTileMode" class="specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='splinterfaith' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('splinterfaith', level.number)">
                                                    <app-gridIcon class="specialchoice"
                                                        [ngClass]="{'fancy-button':finished}"
                                                        [ngbTooltip]="'Splinter Faith Domains: ' + splinterFaithDomains.toString() + '/4'"
                                                        [title]="finished ? '4' : splinterFaithDomains.toString() + '/4'"
                                                        [superTitle]="'icon-ra ra-ocean-emblem'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Additional Heritages-->
                                    <ng-container
                                        *ngFor="let heritageGain of additionalHeritagesAvailable(level.number); trackBy:trackers.trackByIndex">
                                        <ng-container
                                            *ngFor="let heritageIndex of [additionalHeritageIndex(heritageGain.source, level.number)]; trackBy:trackers.trackByIndex">
                                            <ng-container
                                                *ngFor="let finished of [!!character.class.additionalHeritages[heritageIndex].name]; trackBy:trackers.trackByIndex">
                                                <div *ngIf="!isTileMode" class="list-item"
                                                    [ngClass]="{'fullsize-only':finished}">
                                                    <button class="newrow specialchoice"
                                                        [ngClass]="{'fancy-button choicecleared':character.class.additionalHeritages[heritageIndex].name, 'activechoice':shownList()==='additionalheritage' && shownContentLevelNumber()===level.number && this.shownContent()?.id===heritageGain.source}"
                                                        (click)="toggleShownList('additionalheritages', level.number, {id: 'additionalheritage'+level.number+heritageGain.source, source: heritageGain.source})">
                                                        Heritage
                                                        ({{heritageGain.source}}){{finished ? ": "+
                                                        character.class.additionalHeritages[heritageIndex].name
                                                        : ""}}
                                                    </button>
                                                </div>
                                                <button *ngIf="isTileMode" class="specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='additionalheritage' && shownContentLevelNumber()===level.number && this.shownContent()?.id===heritageGain.source}"
                                                    (click)="toggleShownList('additionalheritages', level.number, {id: 'additionalheritage'+level.number+heritageGain.source, source: heritageGain.source})">
                                                    <app-gridIcon class="specialchoice"
                                                        [ngClass]="{'fancy-button':finished}"
                                                        [ngbTooltip]="'Heritage (' + heritageGain.source + ')' + (finished ? ': ' + character.class.additionalHeritages[heritageIndex].name : '')"
                                                        [title]="finished ? character.class.additionalHeritages[heritageIndex].name : ''"
                                                        [superTitle]="'icon-ra ra-sprout-emblem'" />
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <!--Fuse Stance-->
                                    <ng-container
                                        *ngFor="let fuseStanceData of fuseStanceData(level.number); trackBy:trackers.trackByIndex;">
                                        <ng-container
                                            *ngFor="let finished of [fuseStanceData.valueAsStringArray('stances')?.length === 2 && !!fuseStanceData.getValue('name')]; trackBy:trackers.trackByIndex">
                                            <div *ngIf="!isTileMode" class="list-item"
                                                [ngClass]="{'fullsize-only':finished}">
                                                <button class="newrow specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared':finished, 'activechoice':shownList()==='fusestance' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('fusestance', level.number)">
                                                    Fuse Stance{{(finished) ? ": "+
                                                    fuseStanceData.getValue('name') + " (" +
                                                    fuseStanceData.valueAsStringArray('stances')?.join(', ') || '' + ")"
                                                    :
                                                    ""}}
                                                </button>
                                            </div>
                                            <button *ngIf="isTileMode" class="specialchoice"
                                                [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='fusestance' && shownContentLevelNumber()===level.number}"
                                                (click)="toggleShownList('fusestance', level.number)">
                                                <app-gridIcon class="specialchoice"
                                                    [ngClass]="{'fancy-button':finished}"
                                                    [ngbTooltip]="'Fuse Stance' + (finished ? ': ' + fuseStanceData.getValue('name') + ' (' + fuseStanceData.valueAsStringArray('stances')?.join(', ') || '' + ')' : '')"
                                                    [title]="finished ? fuseStanceData.valueAsString('name') || '' : 'Fuse Stance'"
                                                    [superTitle]="'icon-ra ra-double-team'" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <!--Syncretism-->
                                    <ng-container
                                        *ngFor="let syncretismData of syncretismData(level.number); trackBy:trackers.trackByIndex;">
                                        <ng-container
                                            *ngFor="let finished of [!!syncretismData.getValue('deity')]; trackBy:trackers.trackByIndex">
                                            <div *ngIf="!isTileMode" class="list-item"
                                                [ngClass]="{'fullsize-only':finished}">
                                                <button class="newrow specialchoice"
                                                    [ngClass]="{'fancy-button choicecleared':finished, 'activechoice':shownList()==='syncretism' && shownContentLevelNumber()===level.number}"
                                                    (click)="toggleShownList('syncretism', level.number)">
                                                    Second Deity{{(finished) ? ": "+
                                                    syncretismData.getValue('deity') : ""}}
                                                </button>
                                            </div>
                                            <button *ngIf="isTileMode" class="specialchoice"
                                                [ngClass]="{'fancy-button choicecleared fullsize-only':finished, 'activechoice':shownList()==='syncretism' && shownContentLevelNumber()===level.number}"
                                                (click)="toggleShownList('syncretism', level.number)">
                                                <app-gridIcon class="specialchoice"
                                                    [ngClass]="{'fancy-button':finished}"
                                                    [ngbTooltip]="'Second Deity' + (finished ? ': ' + syncretismData.getValue('deity') : '')"
                                                    [title]="finished ? syncretismData.valueAsString('deity') || '' : 'D E I T'"
                                                    [superTitle]="'icon-ra ra-sunbeams'" />
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <!--Lore Training-->
                                    <ng-container
                                        *ngFor="let choice of level.loreChoices; trackBy:trackers.trackByIndex;">
                                        <div *ngIf="choice.available && !isTileMode" class="list-item"
                                            [ngClass]="{'problem':(choice.increases.length > choice.available), 'fullsize-only':choice.increases.length === choice.available}">
                                            <button class="newrow specialchoice"
                                                [ngClass]="{'fancy-button choicecleared':choice.increases.length === choice.available, 'activechoice':shownList()===choice.id}"
                                                (click)="toggleShownList(choice.id, level.number, choice)">
                                                Lore Training
                                                ({{choice.source}}){{(choice.increases.length) ? ": "+
                                                choice.increases[0].name : ""}}
                                            </button>
                                        </div>
                                        <button *ngIf="choice.available && isTileMode" class="specialchoice"
                                            [ngClass]="{'fancy-button choicecleared fullsize-only':choice.increases.length === choice.available, 'activechoice':shownList()===choice.id}"
                                            (click)="toggleShownList(choice.id, level.number, choice)">
                                            <app-gridIcon class="specialchoice"
                                                [ngClass]="{'fancy-button':choice.increases.length === choice.available}"
                                                [ngbTooltip]="'Lore Training (' + choice.source + ')' + ((choice.increases.length) ? ': ' + choice.loreName : '')"
                                                [title]="choice.increases.length === choice.available ? choice.loreName : ''"
                                                [superTitle]="'icon-ra ra-book'" />
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="charactersheet-column" [ngClass]="{'mobile-hide':shownList()===''}">
            <div id="character-choiceArea-top" style="margin-top:-.3em"></div>
            <!--Choice content and templates-->
            <!--Load character content-->
            <ng-container *ngIf="shownList()==='load'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">Load Character</header>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-template #SavegameConnectionFailed>
                        <div class="list-item">
                            Database connection failed.
                        </div>
                    </ng-template>

                    <ng-template #NoSavegamesTemplate>
                        <div class="list-item">
                            No characters available in database.
                        </div>
                    </ng-template>

                    <ng-container *ngIf="savegames() as savegames; else NoSavegamesTemplate">
                        <div *ngIf="savegames.length" class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="loadAsGM" type="checkbox" [(ngModel)]="loadAsGM">
                            <span>
                                <label for="loadAsGM">
                                    <strong>
                                        Load character as GM
                                    </strong>
                                </label>
                                <i class="bi-question-circle"
                                    [ngbPopover]="'Open the character in a read-only mode where it can\'t be saved and can\'t send effects to other players.'"></i>
                            </span>
                        </div>

                        <ng-container
                            *ngFor="let savegame of savegames; let index = index; trackBy:trackers.trackByIndex;">
                            <ng-template #SavegameDeleteModal let-modal>
                                <div class="modal-header">
                                    <header class="sectionHeader modal-title" id="modal-title">
                                        Delete Character
                                    </header>
                                    <button type="button" class="close" aria-label="close"
                                        ngbAutofocus (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body vlist">
                                    <p>Are you sure you want to delete <strong>{{savegame.name}}</strong>?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-secondary"
                                        (click)="modal.dismiss('Cancel click')">
                                        Cancel
                                    </button>
                                    <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">
                                        Ok
                                    </button>
                                </div>
                            </ng-template>
                            <header *ngIf="index === 0 || savegame.partyName !== savegames[index-1].partyName"
                                class="sectionHeader center-aligned">
                                {{savegame.partyName}}</header>
                            <div class="list-item newrow">
                                <span>
                                    <strong>
                                        {{savegame.name}}
                                    </strong>
                                    Level {{savegame.level}}
                                    <span>{{savegameTitle(savegame)}}</span>
                                </span>
                                <span style="flex-basis: auto; flex-grow: 0;">
                                    <button (click)="loadCharacterFromDB(savegame)">
                                        Load
                                        {{loadAsGM ? "as GM" : ""}}
                                    </button>
                                    <button style="margin-left: .3em;"
                                        (click)="openCharacterDeleteModal(SavegameDeleteModal, savegame)">
                                        Delete
                                    </button>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Settings-->
            <ng-container *ngIf="shownList()==='settings'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">Settings</header>
                </div>
                <div id="choiceArea" class="list-item">
                    <div class="list-item">
                        <strong>Look</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input id="accent" type="color" class="character-choice" id="coloraccent"
                                style="padding:0 2px" maxLength="7"
                                [(ngModel)]="character.settings.accent" (ngModelChange)="onChangeAccent()">
                            <label for="accent">
                                <strong>
                                    Color Accent
                                </strong>
                            </label>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input id="darkmode" class="character-choice" type="checkbox"
                                [(ngModel)]="character.settings.darkmode" (ngModelChange)="onToggleDarkmode()">
                            <label for="darkmode">
                                <strong>
                                    Dark mode
                                </strong>
                            </label>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Tags</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="hintsShowMoreInformation" type="checkbox"
                                [(ngModel)]="character.settings.hintsShowMoreInformation">
                            <label for="hintsShowMoreInformation">
                                <strong>
                                    Clicking a tag shows additional information, if available
                                </strong>
                            </label>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Choices</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="autoCloseChoices" type="checkbox"
                                [(ngModel)]="character.settings.autoCloseChoices">
                            <label for="autoCloseChoices">
                                <strong>
                                    Automatically close choices when they are finished
                                </strong>
                            </label>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="showOtherOptions" type="checkbox"
                                [(ngModel)]="character.settings.showOtherOptions">
                            <label for="showOtherOptions">
                                <strong>
                                    Keep showing the other options in finished choices
                                </strong>
                            </label>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Feats</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="unavailableFeats" type="checkbox"
                                [(ngModel)]="character.settings.unavailableFeats">
                            <label for="unavailableFeats">
                                <strong>
                                    Show unavailable feats
                                </strong>
                            </label>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="lowerLevelFeats" type="checkbox"
                                [(ngModel)]="character.settings.lowerLevelFeats">
                            <label for="lowerLevelFeats">
                                <strong>
                                    Show lower level feats
                                </strong>
                            </label>
                        </div>
                        <div class="fullwidth" [ngbCollapse]="!character.settings.unavailableFeats">
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="higherLevelFeats" type="checkbox"
                                    [(ngModel)]="character.settings.higherLevelFeats">
                                <label for="higherLevelFeats">
                                    <strong>
                                        Show higher level feats
                                    </strong>
                                </label>
                            </div>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="archetypeFeats" type="checkbox"
                                [(ngModel)]="character.settings.archetypeFeats">
                            <label for="archetypeFeats">
                                <strong>
                                    Show archetype feats
                                </strong>
                            </label>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="hiddenFeats" type="checkbox"
                                [(ngModel)]="character.settings.hiddenFeats" (ngModelChange)="onHiddenFeatsChange()">
                            <span>
                                <label for="hiddenFeats">
                                    <strong>
                                        Show hidden choices
                                    </strong>
                                </label>
                                <i class="bi-question-circle"
                                    [ngbPopover]="'Certain choices are intended to work automatically, and are hidden if no manual input is required or possible. Showing these can help tracking an issue (e.g. when you take a class choice that grants you a feat, and the feat isn\'t showing up).\n\nThese feat choices may not react as intended to manual input (such as deselecting a feat that was selected automatically).'"></i>
                            </span>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Activities</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="showSkillActivities" type="checkbox"
                                [(ngModel)]="character.settings.showSkillActivities" (ngModelChange)="onUpdateSkills()">
                            <label for="showSkillActivities">
                                <strong>
                                    Show related activities on skills and perception
                                </strong>
                            </label>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Spells</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="showHeightenedSpells" type="checkbox"
                                [(ngModel)]="character.settings.showHeightenedSpells">
                            <label for="showHeightenedSpells">
                                <strong>
                                    Show heightened lower-level spells in spell choices
                                </strong>
                            </label>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="noFriendlyCasterConditions" type="checkbox"
                                [(ngModel)]="character.settings.noFriendlyCasterConditions"
                                (ngModelChange)="onUpdateSpellbook()">
                            <span>
                                <label for="noFriendlyCasterConditions">
                                    <strong>
                                        Avoid informational caster
                                        conditions for friendly spells
                                    </strong>
                                </label>
                                <i class="bi-question-circle"
                                    [ngbPopover]="'When casting targeted spells with a duration, the caster will receive a condition that gives information about the effect and tracks the duration. This option will disable these caster conditions for friendly spells if they have no effect on the caster.'"></i>
                            </span>
                        </div>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="noHostileCasterConditions" type="checkbox"
                                [(ngModel)]="character.settings.noHostileCasterConditions"
                                (ngModelChange)="onUpdateSpellbook()">
                            <span>
                                <label for="noHostileCasterConditions">
                                    <strong>
                                        Avoid informational caster conditions for hostile spells
                                    </strong>
                                </label>
                                <i class="bi-question-circle"
                                    [ngbPopover]="'When casting targeted spells with a duration, the caster will receive a condition that gives information about the effect and tracks the duration. This option will disable these caster conditions for hostile spells if they have no effect on the caster.'"></i>
                            </span>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Integration</strong>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="foundryVTTSendRolls" type="checkbox"
                                [(ngModel)]="character.settings.foundryVTTSendRolls">
                            <span>
                                <label for="foundryVTTSendRolls">
                                    <strong>
                                        Send dice rolls to your Foundry VTT session
                                    </strong>
                                    <span class="newrow">
                                        Requires the External Dice Roll API module
                                    </span>
                                </label>
                            </span>
                        </div>
                        <div class="fullwidth vlist" [ngbCollapse]="!character.settings.foundryVTTSendRolls">
                            <div class="list-item">
                                <strong>
                                    Foundry VTT session URL (e.g. http://your.server:30000)
                                </strong>
                                <input class="newrow" id="foundryVTTUrl" type="text"
                                    [(ngModel)]="character.settings.foundryVTTUrl">
                            </div>
                            <div class="list-item">
                                <strong>
                                    Time to close the Foundry API window (in ms)
                                    <i class="bi-question-circle"
                                        [ngbPopover]="'The API is opened in a new window that closes automatically after this time. If you find that your rolls don\'t turn up in Foundry, try raising this value.'"></i>
                                </strong>
                                <input class="number5" id="foundryVTTTimeout" type="number"
                                    [(ngModel)]="character.settings.foundryVTTTimeout">
                            </div>
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="foundryVTTRollDirectly" type="checkbox"
                                    [(ngModel)]="character.settings.foundryVTTRollDirectly">
                                <span>
                                    <label for="foundryVTTRollDirectly">
                                        <strong>
                                            Quick dice buttons roll directly in Foundry VTT
                                        </strong>
                                    </label>
                                    <i class="bi-question-circle"
                                        [ngbPopover]="'Quick dice buttons usually roll in PECS, where you can send the result to foundry. With this option, quick dice buttons send the dice formula directly to Foundry, where it is evaluated.'"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <strong>Automation</strong>
                        <ng-template #ManualModeDescription>
                            <p>
                                Manual mode is for players who want more control over their attributes, or just need
                                to restore a spell quickly. Many automatic and temporary effects are disabled in manual
                                mode, and buttons are added that allow you to change them yourself. The main differences
                                are listed below:
                            </p>
                            <header class="subsectionHeader">Conditions and effects</header>
                            <ul>
                                <li>
                                    Most mechanisms that would add conditions are disabled in manual mode. Conditions
                                    can be added manually from the conditions menu, including those that would normally
                                    be caused by activities, spells or items.
                                </li>
                                <li>
                                    Any conditions that remain when manual mode is enabled, or that are added manually
                                    afterwards, still cause effects.
                                </li>
                                <li>
                                    Conditions can still end automatically with time or through other factors, for
                                    example by decreasing their value every turn.
                                </li>
                                <li>
                                    Any custom effects still apply.
                                </li>
                                <li>
                                    Hints can still be activated and will then grant their respective effects.
                                </li>
                                <li>
                                    You cannot send or receive any conditions and effects to or from other players.
                                </li>
                            </ul>
                            <header class="subsectionHeader">Activities</header>
                            <ul>
                                <li>
                                    Activities do not cause conditions or one-time effects (like temporary Hit Points).
                                </li>
                                <li>
                                    Activities do not cast spells.
                                </li>
                                <li>
                                    Because activities don't cause conditions or cast spells, you cannot select a target
                                    for an activity.
                                </li>
                                <li>
                                    Activities still grant items on activation (like extra attacks or transformed
                                    weapons).
                                </li>
                                <li>
                                    Activities use charges and go into cooldown. Charges can be restored and cooldown
                                    can be cancelled with a button.
                                </li>
                            </ul>
                            <header class="subsectionHeader">Items</header>
                            <ul>
                                <li>
                                    Consumables do not cause conditions or one-time effects, and do not grant other
                                    items when activated (like extra attacks).
                                </li>
                                <li>
                                    Consumables do not cast spells.
                                </li>
                                <li>
                                    Because consumables don't cause conditions or cast spells, you cannot select a
                                    target for them.
                                </li>
                                <li>
                                    Activities on items can be activated and act according to the above description.
                                </li>
                                <li>
                                    Equipped and invested items still grant abilities, bonuses and penalties.
                                </li>
                                <li>
                                    Being encumbered does not cause the Encumbered condition.
                                </li>
                            </ul>
                            <header class="subsectionHeader">Spells</header>
                            <ul>
                                <li>
                                    Spells do not cause conditions. Because all effects of a spell come from the
                                    conditions it causes, they don't cause any one-time effects, grant any items or
                                    senses or apply any bonuses or penalties.
                                </li>
                                <li>
                                    Because spells have no effects, you cannot select a target for them.
                                </li>
                                <li>
                                    Spells use up spell slots and focus points, and prepared spells get used up. Buttons
                                    are added to reprepare spells and restore spell slots and focus points.
                                </li>
                                <li>
                                    Spells can still go into cooldown, which can be cancelled with a button.
                                </li>
                                <li>
                                    Casting spells does not trigger bloodline powers in sorcerers.
                                </li>
                            </ul>
                            <header class="subsectionHeader">Health</header>
                            <ul>
                                <li>
                                    The wounded and dying value is not tied to a condition. You can manually change both
                                    values with new buttons.
                                </li>
                                <li>
                                    Being reduced to 0 Hit Points does not cause the Dying or Unconscious conditions,
                                    and being healed from 0 Hit points does not end them.
                                </li>
                            </ul>
                            <header class="subsectionHeader">Feats</header>
                            <ul>
                                <li>
                                    Feats are unchanged, as their effects are not temporary.
                                </li>
                                <li>
                                    Feats still cause conditions and effects, and apply one-time effects when you take
                                    them.
                                </li>
                            </ul>
                        </ng-template>
                        <div class="list-item gridicon-fullsizebox">
                            <input class="character-choice" id="manualMode" type="checkbox"
                                [(ngModel)]="character.settings.manualMode" (change)="onToggleManualMode()">
                            <span>
                                <label for="manualMode">
                                    <strong>
                                        Use manual mode
                                    </strong>
                                </label>
                                <i *ngIf="!character.settings.manualMode" class="bi-question-circle"
                                    autoClose=false triggers="click" [ngbPopover]="ManualModeDescription"></i>
                            </span>
                        </div>
                        <div class="fullwidth" [ngbCollapse]="!character.settings.manualMode">
                            <div class="lower">
                                <ng-container *ngTemplateOutlet="ManualModeDescription" />
                            </div>
                        </div>
                        <div class="fullwidth vlist" [ngbCollapse]="character.settings.manualMode">
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="checkMessagesAutomatically" type="checkbox"
                                    [(ngModel)]="character.settings.checkMessagesAutomatically">
                                <label for="checkMessagesAutomatically">
                                    <strong>
                                        Automatically check for new effects from other party members
                                    </strong>
                                </label>
                            </div>
                            <div class="fullwidth" [ngbCollapse]="!character.settings.checkMessagesAutomatically">
                                <div class="list-item gridicon-fullsizebox">
                                    <input class="character-choice" id="applyMessagesAutomatically" type="checkbox"
                                        [(ngModel)]="character.settings.applyMessagesAutomatically">
                                    <span>
                                        <label for="applyMessagesAutomatically">
                                            <strong>
                                                Apply all new effects automatically
                                            </strong>
                                        </label>
                                        <i class="bi-question-circle"
                                            [ngbPopover]="'While automatically checking for new effects, all new effects are immediately applied. You will not be able to select which effects to keep.'"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="sendTurnStartMessage" type="checkbox"
                                    [(ngModel)]="character.settings.sendTurnStartMessage">
                                <span>
                                    <label for="sendTurnStartMessage">
                                        <strong>
                                            Send an event to your party when your turn starts
                                        </strong>
                                    </label>
                                    <i class="bi-question-circle"
                                        [ngbPopover]="'If you have given any conditions to your party members that end on your turn, sending this event will end these conditions on their side. The other players still need to check for effects manually or automatically if they want to benefit from this function.'"></i>
                                </span>
                            </div>
                            <div class="fullwidth" [ngbCollapse]="!character.settings.sendTurnStartMessage">
                                <div class="list-item gridicon-fullsizebox">
                                    <input class="character-choice" id="sendTurnEndMessage" type="checkbox"
                                        [(ngModel)]="character.settings.sendTurnEndMessage">
                                    <label for="sendTurnEndMessage">
                                        <strong>
                                            Send event when your turn ends instead (experimental)
                                        </strong>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--History content-->
            <!--Alternative Ability Values content-->
            <ng-container *ngIf="shownList()==='alternativeBaseValues'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">Alternative Ability Values:
                        {{character.baseValues.length ? 'Used' : 'Not used'}}</header>
                </div>
                <div id="choiceArea" class="list-item">
                    <div class="list-item">
                        <p>
                            You can roll your own ability scores and enter them here. If you use these values, your
                            character gets one fewer free ability boosts than normal from their ancestry and from
                            their background. These ability boosts cannot raise a score above 18. If this would happen,
                            you can put the ability boost into another ability score instead, as if it were a free
                            ability boost. The character does not get any more free ability boosts on level 1.
                        </p>
                    </div>
                    <div class="list-item gridicon-fullsizebox">
                        <input id="useAlternativeBaseValues" class="character-choice" type="checkbox"
                            [checked]="character.baseValues.length" (change)="onBaseValueChange()">
                        <label for="useAlternativeBaseValues">
                            <strong>Enter your own Ability scores</strong>
                        </label>
                    </div>
                    <div *ngFor="let ability of character.baseValues; trackBy:trackers.trackByIndex;"
                        class="list-item gridicon-fullsizebox">
                        <input type="number" class="number2" maxlength="2"
                            [(ngModel)]="ability.baseValue" (blur)="onAbilityChange(ability.name)"
                            (keypress)="positiveNumbersOnly($event)">
                        <strong>{{ability.name}}</strong>
                    </div>
                </div>
            </ng-container>
            <!--Class choice content-->
            <ng-container *ngIf="shownList()==='class'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Class{{(character.class.name) ? ": "+ character.class.name : ""}}
                    </header>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-container *ngFor="let class of availableClasses(); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let checked of [class.name === character.class.name]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let disabled of [class.disabled || (!!character.class.name && (class.name !== character.class.name))]; trackBy:trackers.trackByIndex">
                                <ng-template #ClassChoiceDetailsTemplate>
                                    <header class="spellHeader">{{class.name}}</header>
                                    <div *ngIf="!disabled" class="button newrow no-animation"
                                        [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                        <label>
                                            <input type="checkbox" hidden
                                                [checked]="checked" [disabled]="disabled" (change)="onClassChange(class, $event)">
                                            {{checked ? "Remove" : "Choose"}}
                                        </label>
                                    </div>
                                    <div *ngIf="class.sourceBook" class="newrow left-aligned">
                                        <strong>Source</strong>
                                        <i>{{class.sourceBook}}</i>
                                    </div>
                                    <div *ngFor="let desc of class.desc; trackBy:trackers.trackByIndex;"
                                        class="newrow left-aligned">
                                        <ng-container
                                            *ngFor="let text of desc.value.split('\n\n'); let textIndex = index; trackBy:trackers.trackByIndex;">
                                            <header *ngIf="textIndex === 0" class="featureHeader">{{desc.name}}
                                            </header>
                                            <app-description class="newrow" [text]="text" />
                                        </ng-container>
                                    </div>
                                </ng-template>
                                <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                    <input type="checkbox" class="character-choice" id="{{class.name}}"
                                        [checked]="checked" [disabled]="disabled"
                                        (change)="onClassChange(class, $event)">
                                    <div class="gridicon-fullsizebox" triggers="click"
                                        [ngbPopover]="ClassChoiceDetailsTemplate">
                                        <app-gridIcon [title]="class.name" />
                                        <header class="sectionHeader">
                                            <i *ngIf="class.disabled" style="font-size:200%"
                                                class="penalty bi-exclamation-triangle" [ngbTooltip]="class.disabled">
                                            </i>
                                            <i *ngIf="class.warning" style="font-size:200%" class="absolute bi-exclamation-triangle"
                                                [ngbTooltip]="class.warning">
                                            </i>
                                            <span>
                                                {{class.name}}
                                            </span>
                                        </header>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Ancestry choice content-->
            <ng-container *ngIf="shownList()==='ancestry'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Ancestry{{(character.class.ancestry.name) ? ": "+ character.class.ancestry.name
                        : ""}}
                    </header>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-container *ngFor="let ancestry of availableAncestries(); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let checked of [ancestry.name === character.class.ancestry.name]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let disabled of [ancestry.disabled || (!!character.class.ancestry.name && (ancestry.name !== character.class.ancestry.name))]; trackBy:trackers.trackByIndex">
                                <ng-template #AncestryChoiceDetailsTemplate>
                                    <header class="spellHeader">{{ancestry.name}}</header>
                                    <div *ngIf="!disabled" class="button newrow no-animation"
                                        [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                        <label>
                                            <input type="checkbox" hidden
                                                [checked]="checked" [disabled]="disabled" (change)="onAncestryChange(ancestry, $event)">{{checked ?
                                            "Remove" : "Choose"}}
                                        </label>
                                    </div>
                                    <div class="newrow left-aligned tags">
                                        <app-trait *ngFor="let trait of ancestry.traits; trackBy:trackers.trackByIndex;"
                                            [name]="trait" [trait]="traitFromName(trait)" />
                                    </div>
                                    <div *ngIf="ancestry.sourceBook" class="newrow left-aligned">
                                        <strong>Source</strong>
                                        <i>{{ancestry.sourceBook}}</i>
                                    </div>
                                    <div *ngFor="let desc of ancestry.desc; trackBy:trackers.trackByIndex;"
                                        class="newrow left-aligned">
                                        <ng-container
                                            *ngFor="let text of desc.value.split('\n\n'); let textIndex = index; trackBy:trackers.trackByIndex;">
                                            <header *ngIf="textIndex === 0" class="featureHeader">
                                                {{desc.name}}
                                            </header>
                                            <app-description class="newrow" [text]="text" />
                                        </ng-container>
                                    </div>
                                </ng-template>
                                <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                    <input type="checkbox" class="character-choice" id="{{ancestry.name}}"
                                        [checked]="checked" [disabled]="disabled"
                                        (change)="onAncestryChange(ancestry, $event)">
                                    <div class="gridicon-fullsizebox" triggers="click"
                                        [ngbPopover]="AncestryChoiceDetailsTemplate">
                                        <app-gridIcon [title]="ancestry.name"
                                            [detail]="ancestry.traits.includes('Rare') ? 'Rare' : (ancestry.traits.includes('Uncommon') ? 'Uncommon' : '')" />
                                        <header class="sectionHeader">
                                            <i *ngIf="ancestry.disabled" style="font-size:200%"
                                                class="penalty bi-exclamation-triangle" [ngbTooltip]="ancestry.disabled">
                                            </i>
                                            <i *ngIf="ancestry.warning" style="font-size:200%"
                                                class="absolute bi-exclamation-triangle" [ngbTooltip]="ancestry.warning">
                                            </i>
                                            <span>
                                                {{ancestry.name}}
                                            </span>
                                            <ng-container
                                                *ngFor="let trait of ['Rare', 'Uncommon']; trackBy:trackers.trackByIndex">
                                                <app-trait *ngIf="ancestry.traits.includes(trait)" [name]="trait"
                                                    [trait]="traitFromName(trait)" />
                                            </ng-container>
                                        </header>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Heritage choice content-->
            <ng-container *ngIf="shownList()==='heritage'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Heritage{{(character.class.heritage.name) ? ": "+ character.class.heritage.name
                        : ""}}
                    </header>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-container
                        *ngFor="let heritage of availableHeritages('', character.class.ancestry.name); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let selected of [heritage.name === character.class.heritage.name]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let checked of [!!selected || !!doesCharacterHaveHeritage(heritage.name)]; trackBy:trackers.trackByIndex">
                                <ng-container
                                    *ngFor="let disabled of [!selected && (checked || !!character.class.heritage.name)]; trackBy:trackers.trackByIndex">
                                    <ng-template #HeritageChoiceDetailsTemplate>
                                        <header class="spellHeader">{{heritage.name}}</header>
                                        <div *ngIf="!disabled && !heritage.subTypes.length"
                                            class="button newrow no-animation"
                                            [ngClass]="{'fancy-button':selected, 'disabled':disabled}">
                                            <label>
                                                <input type="checkbox" hidden
                                                    [checked]="selected" [disabled]="disabled" (change)="onHeritageChange(heritage, $event)">{{selected ?
                                                "Remove" : "Choose"}}
                                            </label>
                                        </div>
                                        <div class="newrow left-aligned tags">
                                            <app-trait
                                                *ngFor="let trait of heritage.traits; trackBy:trackers.trackByIndex;"
                                                [name]="trait" [trait]="traitFromName(trait)" />
                                        </div>
                                        <div *ngIf="heritage.sourceBook" class="newrow left-aligned">
                                            <strong>Source</strong>
                                            <i>{{heritage.sourceBook}}</i>
                                        </div>
                                        <div class="newrow left-aligned">
                                            <ng-container
                                                *ngFor="let desc of heritage.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
                                                <app-description class="newrow" [text]="desc" />
                                            </ng-container>
                                        </div>
                                        <ng-container
                                            *ngFor="let activityName of heritage.gainActivities; trackBy:trackers.trackByIndex;">
                                            <div *ngIf="activityFromName(activityName) as activity"
                                                class="newrow left-aligned">
                                                <header class="spellHeader left-aligned">{{activity.name}}
                                                    <app-actionIcons *ngIf="activity.actions"
                                                        [actionString]="activity.actions" />
                                                    {{(activity.activationType) ? activity.activationType : ""}}
                                                </header>
                                                <app-activity class="newrow" [activity]=activity [allowActivate]=false />
                                            </div>
                                        </ng-container>
                                        <!--Subheritages-->
                                        <ng-container
                                            *ngFor="let subheritage of heritage.subTypes; trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let subheritageSelected of [subheritage.name === character.class.heritage.name]; trackBy:trackers.trackByIndex;">
                                                <ng-container
                                                    *ngFor="let subheritageChecked of [subheritageSelected || doesCharacterHaveHeritage(heritage.name)]; trackBy:trackers.trackByIndex;">
                                                    <ng-container
                                                        *ngFor="let subheritageDisabled of [!subheritageSelected && (subheritageChecked || character.class.heritage.name)]; trackBy:trackers.trackByIndex;">
                                                        <div class="list-item"
                                                            [ngClass]="{'selected':subheritageSelected}">
                                                            <div class="gridicon-fullsizebox lower">
                                                                <input type="checkbox" class="character-choice"
                                                                    id="{{subheritage.name}}"
                                                                    [checked]="subheritageChecked"
                                                                    [disabled]="subheritageDisabled"
                                                                    (change)="onHeritageChange(subheritage, $event)">
                                                                <label for="{{subheritage.name}}">
                                                                    <strong>{{subheritage.subType}}</strong>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <!--End Subheritages-->
                                    </ng-template>
                                    <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':selected}">
                                        <input *ngIf="!heritage.subTypes.length" type="checkbox" class="character-choice"
                                            id="{{heritage.name}}" [checked]="checked"
                                            [disabled]="disabled" (change)="onHeritageChange(heritage, $event)">
                                        <button *ngIf="heritage.subTypes.length" class="character-choice"
                                            (click)="HeritageChoiceDetailsPopover.toggle()">+</button>
                                        <div #HeritageChoiceDetailsPopover="ngbPopover" class="gridicon-fullsizebox"
                                            triggers="click" [ngbPopover]="HeritageChoiceDetailsTemplate">
                                            <app-gridIcon [title]="heritage.name"
                                                [detail]="heritage.traits.includes('Rare') ? 'Rare' : (heritage.traits.includes('Uncommon') ? 'Uncommon' : '')" />
                                            <header class="sectionHeader">
                                                <span>{{heritage.name}}&nbsp;</span>
                                                <ng-container
                                                    *ngFor="let trait of ['Rare', 'Uncommon']; trackBy:trackers.trackByIndex">
                                                    <app-trait *ngIf="heritage.traits.includes(trait)" [name]="trait"
                                                        [trait]="traitFromName(trait)" />
                                                </ng-container>
                                            </header>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Languages content-->
            <ng-container *ngIf="shownList()==='languages'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">Languages</header>
                </div>
                <div id="choiceArea" class="list-item">
                    <div *ngIf="character.class.ancestry.recommendedLanguages.length"
                        class="newrow list-item left-aligned">
                        <strong>
                            Recommended Languages
                        </strong>
                        {{character.class.ancestry.recommendedLanguages.join(', ')}}
                    </div>
                    <ng-container *ngFor="let language of character.class.languages; trackBy:trackers.trackByIndex;">
                        <div *ngIf="!language.level || language.level <= shownContentLevelNumber()" class="list-item">
                            <ng-template #LanguageSource>
                                <div class="newrow">
                                    <span>
                                        <strong>Granted by</strong>
                                        {{language.source}}
                                    </span>
                                </div>
                                <div *ngIf="language.level > 0" class="newrow">
                                    <span>
                                        <strong>Level</strong>
                                        {{language.level}}
                                    </span>
                                </div>
                            </ng-template>
                            <div class="newrow" [ngbPopover]="language.source ? LanguageSource : null">
                                <input class="newrow" type="text" [disabled]="language.locked"
                                    [(ngModel)]="language.name" (blur)="onLanguageChange()">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <!--Background choice content-->
            <ng-container *ngIf="shownList()==='background'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Background{{
                        (character.class.background.name)
                        ? ": "+ character.class.background.name
                        : ""
                        }}
                    </header>
                </div>
                <!-- Filter -->
                <div class="list-item" style="margin:0">
                    <strong>Filter</strong>
                    <div class="list-item gridicon-fullsizebox">
                        <input class="character-choice" id="adventureBackgroundsFilter" type="checkbox"
                            [(ngModel)]="adventureBackgrounds">
                        <label for="adventureBackgroundsFilter">
                            <strong>
                                Show adventure backgrounds
                            </strong>
                        </label>
                    </div>
                    <div class="list-item gridicon-fullsizebox">
                        <input class="character-choice" id="regionalBackgroundsFilter" type="checkbox"
                            [(ngModel)]="regionalBackgrounds">
                        <label for="regionalBackgroundsFilter">
                            <strong>
                                Show regional backgrounds
                            </strong>
                        </label>
                    </div>
                </div>
                <div id="choiceArea" class="list-item">
                    <!-- End Filter -->
                    <ng-container *ngFor="let background of availableBackgrounds(); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let checked of [background.name === character.class.background.name || background.name === character.class.background.superType]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let disabled of [!!character.class.background.name && (background.name !== character.class.background.name)]; trackBy:trackers.trackByIndex">
                                <ng-template #BackgroundChoiceDetailsTemplate>
                                    <header class="spellHeader">{{background.name}}</header>
                                    <div *ngIf="!disabled && !background.subTypes" class="button newrow no-animation"
                                        [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                        <label>
                                            <input type="checkbox" hidden
                                                [checked]="checked" [disabled]="disabled" (change)="onBackgroundChange(background, $event)">{{checked ?
                                            "Remove" : "Choose"}}
                                        </label>
                                    </div>
                                    <div class="newrow left-aligned tags">
                                        <app-trait
                                            *ngFor="let trait of background.traits; trackBy:trackers.trackByIndex;"
                                            [name]="trait" [trait]="traitFromName(trait)" />
                                    </div>
                                    <div *ngIf="background.sourceBook" class="newrow left-aligned">
                                        <strong>Source</strong>
                                        <i>{{background.sourceBook}}</i>
                                    </div>
                                    <div *ngIf="background.prerequisites" class="newrow left-aligned">
                                        <strong>Prerequisites</strong>
                                        {{background.prerequisites}}
                                    </div>
                                    <div *ngIf="background.region" class="newrow left-aligned">
                                        <strong>Region</strong>
                                        {{background.region}}
                                    </div>
                                    <div class="newrow left-aligned">
                                        <ng-container
                                            *ngFor="let desc of background.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
                                            <app-description class="newrow" [text]="desc" />
                                        </ng-container>
                                    </div>
                                    <div *ngIf="background.inputRequired" class="list-item lower newrow problem">
                                        <strong>Player input required:</strong>
                                        <div *ngFor="let inputRequired of background.inputRequired.split('\n\n'); trackBy:trackers.trackByIndex;"
                                            class="newrow left-aligned">
                                            <app-description class="newrow" [text]="inputRequired" />
                                        </div>
                                    </div>
                                    <!--Subbackgrounds-->
                                    <div *ngFor="let subbackground of subTypesOfBackground(background.name); trackBy:trackers.trackByIndex;"
                                        class="list-item"
                                        [ngClass]="{'selected':subbackground.name === character.class.background.name}">
                                        <div class="gridicon-fullsizebox lower">
                                            <input type="checkbox" class="character-choice" id="{{subbackground.name}}"
                                                [checked]="subbackground.name === character.class.background.name"
                                                [disabled]="character.class.background.name && (subbackground.name !== character.class.background.name)"
                                                (change)="onBackgroundChange(subbackground, $event)">
                                            <label for="{{subbackground.name}}">
                                                <strong>{{subbackground.subType}}</strong>
                                            </label>
                                        </div>
                                    </div>
                                    <!--End Subbackgrounds-->
                                </ng-template>
                                <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                    <input *ngIf="!background.subTypes" type="checkbox" class="character-choice"
                                        id="{{background.name}}" [checked]="checked"
                                        [disabled]="disabled" (change)="onBackgroundChange(background, $event)">
                                    <button *ngIf="background.subTypes" class="character-choice"
                                        (click)="BackgroundChoiceDetailsPopover.toggle()">+</button>
                                    <div #BackgroundChoiceDetailsPopover="ngbPopover" class="gridicon-fullsizebox"
                                        triggers="click" [ngbPopover]="BackgroundChoiceDetailsTemplate">
                                        <app-gridIcon [title]="background.name"
                                            [detail]="background.traits.includes('Rare') ? 'Rare' : (background.traits.includes('Uncommon') ? 'Uncommon' : '')" />
                                        <header class="sectionHeader">
                                            <span>{{background.name}}&nbsp;</span>
                                            <ng-container
                                                *ngFor="let trait of ['Rare', 'Uncommon', 'Adventure', 'Regional']; trackBy:trackers.trackByIndex">
                                                <app-trait *ngIf="background.traits.includes(trait)" [name]="trait"
                                                    [trait]="traitFromName(trait)" />
                                            </ng-container>
                                        </header>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Deity choice content-->
            <ng-container *ngIf="shownList()==='deity'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Deity{{character.class.deity ? ": "+character.class.deity : ""}}
                    </header>
                </div>
                <!-- Filter -->
                <div class="list-item lower" style="margin:0">
                    <strong>Find (in Name, Description or Domains)</strong>
                    <span class="hlist">
                        <input id="deityWordFilter" type=text [(ngModel)]="deityWordFilter" />
                    </span>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-container *ngFor="let deity of availableDeities('', false); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let checked of [deity.name === character.class.deity]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let disabled of [!!character.class.deity && (deity.name !== character.class.deity)]; trackBy:trackers.trackByIndex">
                                <ng-template #DeityChoiceDetailsTemplate>
                                    <header class="spellHeader">{{deity.name}}</header>
                                    <div *ngIf="!disabled" class="button newrow no-animation"
                                        [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                        <label>
                                            <input type="checkbox" hidden
                                                [checked]="checked" [disabled]="disabled" (change)="onDeityChange(deity, $event)">
                                            {{checked ? "Remove" : "Choose"}}
                                        </label>
                                    </div>
                                    <header *ngIf="deity.nickname" class="subsectionHeader">{{deity.nickname}}</header>
                                    <div *ngIf="deity.sourceBook" class="newrow left-aligned">
                                        <strong>Source</strong>
                                        <i>{{deity.sourceBook}}</i>
                                    </div>
                                    <ng-container
                                        *ngFor="let desc of deity.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
                                        <app-description class="newrow" [text]="desc" />
                                    </ng-container>
                                    <div *ngIf="deity.edicts" class="newrow left-aligned gap-text">
                                        <strong>Edicts</strong>
                                        {{deity.edicts.join(", ")}}
                                    </div>
                                    <div *ngIf="deity.anathema" class="newrow left-aligned gap-text">
                                        <strong>Anathema</strong>
                                        {{deity.anathema.join(", ")}}
                                    </div>
                                    <div *ngIf="deity.areasOfConcern" class="newrow left-aligned gap-text">
                                        <strong>Areas of Concern</strong>
                                        {{deity.areasOfConcern}}
                                    </div>
                                    <div *ngIf="deity.followerAlignments.length" class="newrow left-aligned gap-text">
                                        <strong>Follower Alignments</strong>
                                        {{deity.followerAlignments.join(", ")}}
                                    </div>
                                    <div *ngIf="deity.pantheonMembers.length" class="newrow left-aligned gap-text">
                                        <strong>Pantheon Members</strong>
                                        {{deity.pantheonMembers.join(", ")}}
                                    </div>
                                    <div *ngIf="deity.divineAbility.length" class="newrow left-aligned gap-text">
                                        <strong>Divine Ability</strong>
                                        {{deity.divineAbility.join(" or ")}}
                                    </div>
                                    <div *ngIf="deity.divineFont.length" class="newrow left-aligned gap-text">
                                        <strong>Divine Font</strong>
                                        {{deity.divineFont.join(" or ")}}
                                    </div>
                                    <div *ngIf="deity.divineSkill.length" class="newrow left-aligned gap-text">
                                        <strong>Divine Skill</strong>
                                        {{deity.divineSkill.join(" or ")}}
                                    </div>
                                    <div *ngIf="deity.favoredWeapon.length" class="newrow left-aligned gap-text">
                                        <strong>Favored Weapon</strong>
                                        {{deity.favoredWeapon.join(" or ")}}
                                    </div>
                                    <div *ngIf="deity.domains.length" class="newrow left-aligned gap-text">
                                        <strong>Domains</strong>
                                        {{deity.domains.join(", ")}}
                                    </div>
                                    <div *ngIf="deity.alternateDomains.length" class="newrow left-aligned gap-text">
                                        <strong>Alternate Domains</strong>
                                        {{deity.alternateDomains.join(", ")}}
                                    </div>
                                    <ng-container *ngIf="deity.clericSpells.length">
                                        <div class="newrow left-aligned">
                                            <strong>Cleric Spells</strong>
                                        </div>
                                        <div *ngFor="let spell of deity.clericSpells; trackBy:trackers.trackByIndex;"
                                            class="list-item lower left-aligned">
                                            <strong>
                                                Level {{spell.level}}
                                            </strong>
                                            {{spell.name}}
                                            {{(spell.restrictionDesc ? "("+spell.restrictionDesc+")" : "")}}
                                        </div>
                                    </ng-container>
                                </ng-template>
                                <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                    <input type="checkbox" class="character-choice" id="{{deity.name}}"
                                        [checked]="checked" [disabled]="disabled"
                                        (change)="onDeityChange(deity, $event)">
                                    <div class="gridicon-fullsizebox" triggers="click"
                                        [ngbPopover]="DeityChoiceDetailsTemplate">
                                        <app-gridIcon [title]="deity.name" [detail]="deity.alignment" />
                                        <header class="sectionHeader">
                                            <span>
                                                {{deity.name}}
                                            </span>
                                            <cite *ngIf="deity.alignment" class="trait">{{deity.alignment}}</cite>
                                        </header>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Level content-->
            <!--Ability Choice Content-->
            <ng-container *ngIf="activeAbilityChoiceContent$ | async as abilityContent">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        {{abilityChoiceTitle(abilityContent.choice)}}
                    </header>
                    <div class="newrow center-aligned">
                        <span>
                            <button *ngIf="abilityContent.choice.bonus" class="center-aligned"
                                (click)="removeBonusAbilityChoice(abilityContent.choice, abilityContent.levelNumber)">
                                Remove bonus ability choice
                            </button>
                        </span>
                    </div>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-container
                        *ngFor="let ability of availableAbilities(abilityContent.choice, abilityContent.levelNumber); trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let checked of [isAbilityTakenByThisChoice(ability, abilityContent.choice, abilityContent.levelNumber)]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let disabled of [!!this.cannotBoostAbility(ability, abilityContent.levelNumber, abilityContent.choice).length || (abilityContent.choice.boosts.length === maxAbilityBoostsAvailableInChoice(abilityContent.choice) && !checked)]; trackBy:trackers.trackByIndex">
                                <div class="list-item gridicon-fullsizebox"
                                    [ngClass]="{'selected':checked, 'problem':isAbilityIllegal(abilityContent.levelNumber, ability)}">
                                    <input type="checkbox" class="character-choice" id="{{ability.name}}"
                                        [checked]="checked"
                                        [disabled]="disabled" (change)="onBoostAbility(ability.name, $event, abilityContent.choice, false)">
                                    <div *ngFor="let baseValue of [abilityBaseValue(ability, abilityContent.levelNumber)]; trackBy:trackers.trackByIndex;"
                                        class="gridicon-fullsizebox">
                                        <app-gridIcon triggers="hover:click" [title]="ability.name"
                                            [ngbPopover]="baseValue.explain" [openDelay]=1
                                            [superTitle]="baseValue.result.toString()" />
                                        <header class="sectionHeader">
                                            <span>{{ability.name}}&nbsp;</span>
                                            <cite *ngFor="let reason of cannotBoostAbility(ability, abilityContent.levelNumber, abilityContent.choice); trackBy:trackers.trackByIndex;"
                                                class="problem">
                                                {{reason}}
                                            </cite>
                                            <cite *ngIf="isAbilityIllegal(abilityContent.levelNumber, ability)"
                                                class="problem"
                                                [ngbPopover]="'Abilities cannot be raised above 18 on level 1.'">
                                                Limit reached
                                            </cite>
                                        </header>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Skill Choice Content-->
            <ng-container *ngIf="activeSkillChoiceContent$ | async as skillContent">
                <app-skillchoice [choice]="skillContent.choice" [levelNumber]="skillContent.levelNumber"
                    [showTitle]="false" [showChoice]="skillContent.name" [excludeTemporary]="true"
                    (showSkillChoiceMessage)="receiveChoiceMessage($event)" />
            </ng-container>
            <!--Feat Choice Content-->
            <ng-container *ngIf="activeFeatChoiceContent$ | async as featContent">
                <app-featchoice [choice]="featContent.choice"
                    [levelNumber]="featContent.levelNumber" [showTitle]="false"
                    [showFeat]="shownItem()" [showChoice]="featContent.name" [unavailableFeats]="character.settings.unavailableFeats"
                    [lowerLevelFeats]="character.settings.lowerLevelFeats" [higherLevelFeats]="character.settings.higherLevelFeats"
                    [archetypeFeats]="character.settings.archetypeFeats"
                    (showFeatChoiceMessage)="receiveChoiceMessage($event)"
                    (showFeatMessage)="receiveFeatMessage($event)" />
            </ng-container>
            <!--Lore Training content-->
            <ng-container *ngIf="activeLoreChoiceContent$ | async as loreContent">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Lore Training ({{loreContent.choice.source}}){{(loreContent.choice.increases.length) ? ": "+
                        loreContent.choice.loreName : ""}}
                    </header>
                    <div class="newrow center-aligned">
                        <span>
                            <button *ngIf="loreContent.choice.bonus" class="center-aligned"
                                (click)="removeBonusLoreChoice(loreContent.choice, loreContent.levelNumber)">
                                Remove bonus lore training
                            </button>
                        </span>
                    </div>
                </div>
                <div id="choiceArea" class="list-item">
                    <ng-container
                        *ngFor="let checked of [!!skillIncreasesByLevel(loreContent.levelNumber, 'Lore: '+loreContent.choice.loreName, loreContent.choice.source, loreContent.choice.id).length]; trackBy:trackers.trackByIndex">
                        <ng-container
                            *ngFor="let illegal of [skills('Lore: '+loreContent.choice.loreName, {}, {noSubstitutions: true}).length && !checked]; trackBy:trackers.trackByIndex">
                            <ng-container
                                *ngFor="let disabled of [!loreContent.choice.loreName || !!illegal]; trackBy:trackers.trackByIndex">
                                <div *ngIf="loreContent.choice.loreDesc" class="newrow left-aligned list-item">
                                    <strong>Lore Type</strong>
                                    {{loreContent.choice.loreDesc}}
                                </div>
                                <div class="newrow list-item gridicon-fullsizebox">
                                    <div class="button no-animation" style="max-width: fit-content;"
                                        [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                        <label>
                                            <input type="checkbox" hidden
                                                id="{{loreContent.choice.id}}" [checked]="checked"
                                                [disabled]="disabled" (change)="onLoreChange($event, loreContent.choice)">
                                            {{checked ? "Change" : "Save"}}
                                        </label>
                                    </div>
                                    <header class="sectionHeader gap-text">
                                        <cite *ngIf="illegal" class="problem">
                                            Lore already trained
                                        </cite>
                                        <input id="LoreName" class="short" type="text"
                                            maxLength="20" [disabled]="checked"
                                            [(ngModel)]="loreContent.choice.loreName" (blur)="onLoreNameChange()">
                                        <span> Lore</span>
                                    </header>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <!--Animal Companion Content-->
            <ng-container *ngIf="activeSpecialChoiceShown('animalcompanion') as companionContent">
                <ng-container *ngFor="let companion of [companion]; trackBy:trackers.trackByIndex">
                    <div class="newrow list-item padding-8 center-aligned">
                        <header class="box-header sectionHeader">
                            Animal Companion{{(companion.class.ancestry.name) ? ": "+ companion.class.ancestry.name
                            : ""}}
                        </header>
                    </div>
                    <div id="choiceArea" class="list-item">
                        <div class="newrow list-item">
                            <button class="newrow list-item" (click)="onResetCompanion()">
                                <header class="sectionHeader">Reset Animal Companion</header>
                            </button>
                            <div class="newrow list-item left-aligned">
                                <strong>
                                    Name
                                </strong>
                                <input id="CompanionName" type="text" maxLength="30" [(ngModel)]="companion.name">
                            </div>
                            <div class="newrow list-item left-aligned">
                                <strong>
                                    Animal
                                </strong>
                                <input id="CompanionSpecies" type="text" maxLength="30" [(ngModel)]="companion.species">
                            </div>
                        </div>
                        <div class="list-item transparent">
                            <header class="sectionHeader">Type</header>
                        </div>
                        <ng-container *ngFor="let type of availableCompanionTypes(); trackBy:trackers.trackByIndex;">
                            <ng-container
                                *ngFor="let checked of [type.name === companion.class.ancestry.name]; trackBy:trackers.trackByIndex">
                                <ng-container
                                    *ngFor="let disabled of [!!companion.class.ancestry.name && (type.name !== companion.class.ancestry.name)]; trackBy:trackers.trackByIndex">
                                    <ng-template #AnimalCompanionDetailsTemplate>
                                        <header class="spellHeader">{{type.name}}</header>
                                        <div *ngIf="!disabled" class="button newrow no-animation"
                                            [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                            <label>
                                                <input type="checkbox" hidden
                                                    [checked]="checked" [disabled]="disabled" (change)="onChangeCompanionType(type, $event)">
                                                {{checked ? "Remove" : "Choose"}}
                                            </label>
                                        </div>
                                        <div class="newrow left-aligned">
                                            <div class="newrow left-aligned tags">
                                                <app-trait
                                                    *ngFor="let trait of type.traits; trackBy:trackers.trackByIndex;"
                                                    [name]="trait" [trait]="traitFromName(trait)" />
                                            </div>
                                            <div *ngIf="type.sourceBook" class="newrow left-aligned">
                                                <strong>Source</strong>
                                                <i>{{type.sourceBook}}</i>
                                            </div>
                                            <ng-container
                                                *ngFor="let desc of type.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
                                                <app-description class="newrow" [text]="desc" />
                                            </ng-container>
                                            <p>
                                                <strong>Size</strong> {{size(type.size)}}
                                            </p>
                                            <p
                                                *ngFor="let attack of grantedCompanionAttacks(type); trackBy:trackers.trackByIndex;">
                                                <strong>Melee</strong>
                                                {{attack.displayName}},
                                                <strong>Damage</strong>
                                                {{attack.dicenum+"d"+attack.dicesize}}
                                            </p>
                                            <p>
                                                <span
                                                    *ngFor="let ability of animalCompanionAbilities(type); let index = index; trackBy:trackers.trackByIndex;">
                                                    {{index ? ", " : ""}}<strong>{{ability.name}}</strong>
                                                    {{ability.modifier}}
                                                </span>
                                            </p>
                                            <p>
                                                <strong>Hit Points</strong>
                                                {{type.hitPoints}}
                                            </p>
                                            <p>
                                                <strong>Skill</strong>
                                                {{type.skillChoices[0].increases[0].name}}
                                            </p>
                                            <p>
                                                <strong>Senses</strong>
                                                {{type.senses.join(",")}}
                                            </p>
                                            <p>
                                                <strong>Speed</strong>
                                                <span
                                                    *ngFor="let speed of type.speeds; let index = index; trackBy:trackers.trackByIndex;">
                                                    {{index > 0 ? ", " : ""}}{{speed.name.split(' ')[0] + " " +
                                                    speed.value + " feet"}}
                                                </span>
                                            </p>
                                            <p>
                                                <strong>Support Benefit</strong>
                                                {{type.supportBenefit}}
                                            </p>
                                            <p *ngIf="type.specialdesc.length">
                                                <strong>Special</strong>
                                                {{type.specialdesc}}
                                            </p>
                                        </div>
                                        <ng-container
                                            *ngIf="type.activities[1] as gain;">
                                            <div *ngIf="activityFromName(gain.name) as activity"
                                                class="newrow left-aligned">
                                                <header class="spellHeader left-aligned">
                                                    Advanced Maneuver: {{activity.name}}
                                                    <app-actionIcons *ngIf="activity.actions"
                                                        [actionString]="activity.actions" />
                                                    {{(activity.activationType) ? activity.activationType : ""}}
                                                </header>
                                                <app-activity class="newrow" [activity]="activity" [allowActivate]="false" />
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                    <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                        <input type="checkbox" class="character-choice" id="{{type.name}}"
                                            [checked]="checked" [disabled]="disabled"
                                            (change)="onChangeCompanionType(type, $event)">
                                        <div class="gridicon-fullsizebox">
                                            <app-gridIcon [title]="type.name" />
                                            <header class="sectionHeader">
                                                <span triggers="click"
                                                    [ngbPopover]="AnimalCompanionDetailsTemplate">{{type.name}}</span>
                                            </header>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <!--Companion Specialization Content-->
            <ng-container *ngIf="activeSpecialChoiceShown('companionspecialization') as companionSpecializationContent">
                <ng-container *ngFor="let companion of [companion]; trackBy:trackers.trackByIndex">
                    <ng-container
                        *ngFor="let available of [companionSpecializationsAvailable(companionSpecializationContent.levelNumber)]; trackBy:trackers.trackByIndex;">
                        <ng-container
                            *ngFor="let takenSpecializations of [companionSpecializationsOnLevel(companionSpecializationContent.levelNumber)]; trackBy:trackers.trackByIndex">
                            <div class="newrow list-item padding-8 center-aligned">
                                <header class="box-header sectionHeader">
                                    {{companionSpecializationChoiceTitle(available, takenSpecializations)}}
                                </header>
                            </div>
                            <div class="list-item">
                                <p>
                                    The first time an animal gains a specialization, it gains the following: Its
                                    proficiency rank for unarmed attacks increases to expert. Its proficiency ranks
                                    for saving throws and Perception increase to master. Increase its Dexterity
                                    modifier by 1 and its Intelligence modifier by 2. Its unarmed attack damage
                                    increases from two dice to three dice, and it increases its additional damage
                                    with unarmed attacks from 2 to 4 or from 3 to 6.
                                </p>
                            </div>
                            <div id="choiceArea" class="list-item">
                                <ng-container
                                    *ngFor="let spec of availableCompanionSpecializations(companionSpecializationContent.levelNumber); trackBy:trackers.trackByIndex;">
                                    <ng-container
                                        *ngFor="let taken of [takenSpecializations.includes(spec.name)]; trackBy:trackers.trackByIndex">
                                        <ng-container
                                            *ngFor="let checked of [!!taken || !!hasCompanionTakenThisSpecialization(spec.name)]; trackBy:trackers.trackByIndex">
                                            <ng-container
                                                *ngFor="let disabled of [(!!hasCompanionTakenThisSpecialization(spec.name) || (takenSpecializations.length === available)) && !taken]; trackBy:trackers.trackByIndex">
                                                <ng-template #CompanionSpecializationDetailsTemplate>
                                                    <header class="spellHeader">{{spec.name}}</header>
                                                    <div *ngIf="!disabled && (taken || !checked)"
                                                        class="button newrow no-animation"
                                                        [ngClass]="{'fancy-button':taken}">
                                                        <label>
                                                            <input type="checkbox"
                                                                hidden
                                                                [checked]="checked" [disabled]="disabled" (change)="onChangeCompanionSpecialization(spec, $event, companionSpecializationContent.levelNumber)">
                                                            {{checked ? "Remove" : "Choose"}}
                                                        </label>
                                                    </div>
                                                    <div class="newrow left-aligned">
                                                        <cite *ngIf="checked && !taken" class="problem">
                                                            Already taken
                                                        </cite>
                                                    </div>
                                                    <div *ngIf="spec.sourceBook" class="newrow left-aligned">
                                                        <strong>Source</strong>
                                                        <i>{{spec.sourceBook}}</i>
                                                    </div>
                                                    <div class="newrow left-aligned">
                                                        <p>
                                                            {{spec.desc}}
                                                        </p>
                                                    </div>
                                                </ng-template>
                                                <div class="list-item gridicon-fullsizebox"
                                                    [ngClass]="{'selected':taken}">
                                                    <input type="checkbox" class="character-choice" id="{{spec.name}}"
                                                        [checked]="checked"
                                                        [disabled]="disabled" (change)="onChangeCompanionSpecialization(spec, $event, companionSpecializationContent.levelNumber)">
                                                    <div class="gridicon-fullsizebox">
                                                        <app-gridIcon [title]="spec.name" />
                                                        <header class="sectionHeader">
                                                            <span triggers="click"
                                                                [ngbPopover]="CompanionSpecializationDetailsTemplate">{{spec.name}}</span>
                                                        </header>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!--Familiar Content-->
            <ng-container *ngIf="activeSpecialChoiceShown('familiar') as familiarContent">
                <ng-container *ngFor="let familiar of [familiar]; trackBy:trackers.trackByIndex">
                    <div class="newrow list-item padding-8 center-aligned">
                        <header class="box-header sectionHeader">
                            Familiar
                        </header>
                    </div>
                    <div id="choiceArea" class="list-item">
                        <div class="newrow list-item">
                            <button class="newrow list-item" (click)="onResetFamiliar()">
                                <header class="sectionHeader">Reset Familiar</header>
                            </button>
                            <div class="newrow list-item left-aligned">
                                <strong>
                                    Name
                                </strong>
                                <input id="FamiliarName" type="text" maxLength="30" [(ngModel)]="familiar.name">
                            </div>
                            <div class="newrow list-item left-aligned">
                                <strong>
                                    Creature
                                </strong>
                                <input id="FamiliarCreature" type="text" maxLength="30" [(ngModel)]="familiar.species">
                            </div>
                            <div class="list-item left-aligned gridicon-fullsizebox">
                                <input class="character-choice" id="FamiliarSwimmer" type="checkbox"
                                    [checked]="isFamiliarSwimmer()" (click)="onFamiliarSpeedChange($event)">
                                <label for="FamiliarSwimmer">
                                    <strong>
                                        Gain Swim Speed instead of Land Speed
                                    </strong>
                                </label>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <!--Different Worlds content-->
            <ng-container *ngIf="activeSpecialChoiceShown('differentworlds') as differentWorldsContent">
                <ng-container
                    *ngFor="let differentWorldsData of differentWorldsData(differentWorldsContent.levelNumber); trackBy:trackers.trackByIndex;">
                    <div class="newrow list-item padding-8 center-aligned">
                        <header class="box-header sectionHeader">
                            Different Worlds{{!!differentWorldsData.getValue('background') ? ": "+
                            differentWorldsData.getValue('background') : ""}}
                        </header>
                    </div>
                    <div id="choiceArea" class="list-item">
                        <div class="newrow list-item">
                            <div class="newrow list-item left-aligned">
                                <strong>Alternative name</strong>
                                <input type="text" maxLength="30"
                                    [ngModel]="differentWorldsData.getValue('name')" (input)="differentWorldsData.setValue('name', $event)"
                                    (blur)=onNameChange()>
                            </div>
                        </div>
                        <div class="list-item transparent">
                            <header class="sectionHeader">Alternative background</header>
                        </div>
                        <!-- Filter -->
                        <div class="list-item lower">
                            <strong>Filter</strong>
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="adventureBackgroundsFilter" type="checkbox"
                                    [(ngModel)]="adventureBackgrounds">
                                <label for="adventureBackgroundsFilter">
                                    <strong>Show adventure backgrounds
                                    </strong>
                                </label>
                            </div>
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" id="regionalBackgroundsFilter" type="checkbox"
                                    [(ngModel)]="regionalBackgrounds">
                                <label for="regionalBackgroundsFilter">
                                    <strong>Show regional backgrounds
                                    </strong>
                                </label>
                            </div>
                        </div>
                        <!-- End Filter -->
                        <ng-container *ngFor="let background of filteredBackgrounds(); trackBy:trackers.trackByIndex;">
                            <ng-container
                                *ngFor="let selected of [background.name === differentWorldsData.getValue('background')]; trackBy:trackers.trackByIndex">
                                <ng-container
                                    *ngFor="let checked of [selected || background.name === character.class.background.name || background.name === character.class.background.superType]; trackBy:trackers.trackByIndex">
                                    <ng-container
                                        *ngFor="let disabled of [!selected && (checked || !!differentWorldsData.getValue('background'))]; trackBy:trackers.trackByIndex">
                                        <ng-template #DifferentWorldsBackgroundChoiceDetailsTemplate>
                                            <header class="spellHeader">{{background.name}}</header>
                                            <div *ngIf="!disabled" class="button newrow no-animation"
                                                [ngClass]="{'fancy-button':selected, 'disabled':disabled}">
                                                <label>
                                                    <input type="checkbox"
                                                        hidden
                                                        [checked]="checked" [disabled]="disabled" (change)="onDifferentWorldsBackgroundChange(differentWorldsContent.levelNumber, differentWorldsData, background, $event)">{{selected
                                                    ? "Remove" : "Choose"}}
                                                </label>
                                            </div>
                                            <div class="newrow left-aligned tags">
                                                <app-trait
                                                    *ngFor="let trait of background.traits; trackBy:trackers.trackByIndex;"
                                                    [name]="trait" [trait]="traitFromName(trait)" />
                                            </div>
                                            <div *ngIf="background.sourceBook" class="newrow left-aligned">
                                                <strong>Source</strong>
                                                <i>{{background.sourceBook}}</i>
                                            </div>
                                            <div *ngIf="background.prerequisites" class="newrow left-aligned">
                                                <strong>Prerequisites</strong>
                                                {{background.prerequisites}}
                                            </div>
                                            <div *ngIf="background.region" class="newrow left-aligned">
                                                <strong>Region</strong>
                                                {{background.region}}
                                            </div>
                                            <div class="newrow left-aligned">
                                                <ng-container
                                                    *ngFor="let desc of background.desc.split('\n\n'); let index = index; trackBy:trackers.trackByIndex;">
                                                    <ng-container *ngIf="index === 0">
                                                        <app-description class="newrow" [text]="desc" />
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="background.loreChoices[0]?.loreName !== ''">
                                                    <p>
                                                        You're trained in {{background.loreChoices[0].loreName}}
                                                        Lore.
                                                    </p>
                                                </ng-container>
                                                <ng-container *ngIf="background.loreChoices[0]?.loreDesc !== ''">
                                                    <p>
                                                        You're trained in
                                                        {{background.loreChoices[0].loreDesc.substr(0,1).toLowerCase()}}{{background.loreChoices[0].loreDesc.substr(1)}}.
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </ng-template>
                                        <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':selected}">
                                            <input type="checkbox" class="character-choice" id="{{background.name}}"
                                                [checked]="checked"
                                                [disabled]="disabled" (change)="onDifferentWorldsBackgroundChange(differentWorldsContent.levelNumber, differentWorldsData, background, $event)">
                                            <div class="gridicon-fullsizebox"
                                                triggers="click"
                                                [ngbPopover]="DifferentWorldsBackgroundChoiceDetailsTemplate">
                                                <app-gridIcon [title]="background.name"
                                                    [detail]="background.traits.includes('Rare') ? 'Rare' : (background.traits.includes('Uncommon') ? 'Uncommon' : '')" />
                                                <header class="sectionHeader">
                                                    <span>{{background.name}}&nbsp;</span>
                                                    <ng-container
                                                        *ngFor="let trait of ['Rare', 'Uncommon', 'Adventure', 'Regional']; trackBy:trackers.trackByIndex">
                                                        <app-trait *ngIf="background.traits.includes(trait)"
                                                            [name]="trait" [trait]="traitFromName(trait)" />
                                                    </ng-container>
                                                </header>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <!--Blessed Blood Content-->
            <ng-container *ngIf="activeSpecialChoiceShown('blessedblood') as blessedBloodContent">
                <ng-container
                    *ngFor="let blessedBloodSpells of [blessedBloodSpellsTaken()]; trackBy:trackers.trackByIndex;">
                    <div class="newrow list-item padding-8 center-aligned">
                        <header class="box-header sectionHeader">
                            Blessed Blood Deity Spells: {{blessedBloodSpells}}/3
                        </header>
                    </div>
                    <div class="list-item">
                        <p>
                            Add up to three of your deity's spells (spells your deity grants to clerics) to your
                            spell list. They are not automatically added to your repertoire, but you can select them
                            just as you would spells normally on the divine spell list.
                        </p>
                    </div>
                    <div id="choiceArea" class="list-item">
                        <ng-container *ngFor="let spell of blessedBloodDeitySpells(); trackBy:trackers.trackByIndex;">
                            <ng-container
                                *ngFor="let checked of [!!isSpellTakenInBlessedBlood(spell)]; trackBy:trackers.trackByIndex">
                                <ng-container
                                    *ngFor="let disabled of [blessedBloodSpells >= 3 && !checked]; trackBy:trackers.trackByIndex">
                                    <ng-template #BlessedBloodChoiceDetailsTemplate>
                                        <header class="spellHeader newrow">
                                            <span style="flex-grow:10">
                                                {{spell.name}}
                                                <app-actionIcons *ngIf="spell.actions" [actionString]="spell.actions" />
                                                {{(spell.castType) ? spell.castType : ""}}
                                            </span>
                                            <span style="flex-basis:auto; flex-shrink:0">
                                                Spell {{spell.levelreq}}
                                            </span>
                                        </header>
                                        <div *ngIf="!disabled" class="button newrow no-animation"
                                            [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                            <label>
                                                <input type="checkbox"
                                                    hidden
                                                    [checked]="checked" [disabled]="disabled" (change)="onBlessedBloodSpellTaken(spell, blessedBloodContent.levelNumber, $event)">
                                                {{checked ? "Remove" : "Choose"}}
                                            </label>
                                        </div>
                                        <app-spell class="newrow" [spell]=spell [spellLevel]="spell.levelreq" />
                                    </ng-template>
                                    <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                        <input type="checkbox" class="character-choice" id="{{spell.name}}"
                                            [checked]="checked"
                                            [disabled]="disabled" (change)="onBlessedBloodSpellTaken(spell, blessedBloodContent.levelNumber, $event)">
                                        <div class="gridicon-fullsizebox"
                                            triggers="click" [ngbPopover]="BlessedBloodChoiceDetailsTemplate">
                                            <app-gridIcon [title]="spell.name" />
                                            <header class="sectionHeader">
                                                <span>{{spell.name}}</span>
                                            </header>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <!--Splinter Faith Content-->
            <ng-container *ngIf="activeSpecialChoiceShown('splinterfaith') as splinterFaithContent">
                <ng-container
                    *ngFor="let splinterFaithDomains of [splinterFaithDomains()]; trackBy:trackers.trackByIndex;">
                    <div class="newrow list-item padding-8 center-aligned">
                        <header class="box-header sectionHeader">
                            Splinter Faith Domains: {{splinterFaithDomains.length}}/4
                        </header>
                    </div>
                    <div class="list-item">
                        <p>
                            Choose four domains. These domains must be chosen from among your deity's domains, your
                            deity's alternate domains, and up to one domain that isn't on either list and isn't
                            anathematic to your deity. Any domain spell you cast from a domain that isn't on either of
                            your deity's lists is always heightened to 1 level lower than usual for a focus spell. For
                            the purpose of abilities that depend on your deity's domains, the four domains you chose are
                            your deity's domains, and any of your deity's domains you didn't choose are now among your
                            deity's alternate domains.
                        </p>
                    </div>
                    <div id="choiceArea" class="list-item">
                        <ng-container
                            *ngFor="let availableDomains of [splinterFaithAvailableDomains()]; trackBy:trackers.trackByIndex;">
                            <ng-container *ngFor="let domain of availableDomains; trackBy:trackers.trackByIndex;">
                                <div *ngIf="domain.title" class="list-item newrow">
                                    <header class="sectionHeader">
                                        {{domain.title}}
                                    </header>
                                </div>
                                <ng-container
                                    *ngFor="let checked of [splinterFaithDomains.includes(domain.domain.name)]; trackBy:trackers.trackByIndex">
                                    <ng-container
                                        *ngFor="let disabled of [!checked && (splinterFaithDomains.length >= 4 || (domain.type === 3 && !!isSplinterFaithThirdDomainTaken(availableDomains, splinterFaithDomains)))]; trackBy:trackers.trackByIndex">
                                        <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                            <input type="checkbox" class="character-choice" id="{{domain.domain.name}}"
                                                [checked]="checked"
                                                [disabled]="disabled" (change)="onSplinterFaithDomainTaken(domain.domain.name, $event)">
                                            <ng-template #DomainDescTemplate>
                                                <div *ngIf="domain.domain.sourceBook" class="newrow left-aligned">
                                                    <strong>Source</strong>
                                                    <i>{{domain.domain.sourceBook}}</i>
                                                </div>
                                                <div class="newrow left-aligned">
                                                    {{domain.domain.desc}}
                                                </div>
                                            </ng-template>
                                            <div class="gridicon-fullsizebox" triggers="click"
                                                [ngbPopover]="DomainDescTemplate">
                                                <app-gridIcon [title]="domain.domain.name" />
                                                <header class="sectionHeader">
                                                    <span>{{domain.domain.name}}</span>
                                                </header>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <!--Fuse Stance content-->
            <ng-container *ngIf="activeSpecialChoiceShown('fusestance') as fuseStanceContent">
                <ng-container
                    *ngFor="let fuseStanceData of fuseStanceData(fuseStanceContent.levelNumber); trackBy:trackers.trackByIndex">
                    <ng-container
                        *ngFor="let finished of [fuseStanceData.valueAsStringArray('stances')?.length === 2]; trackBy:trackers.trackByIndex">
                        <div class="newrow list-item padding-8 center-aligned">
                            <header class="box-header sectionHeader">
                                {{fuseStanceChoiceTitle(finished, fuseStanceData)}}
                            </header>
                        </div>
                        <div class="list-item">
                            <p>
                                Choose two stances you know and combine them into a single fused stance. Give your
                                new fused stance a unique name. When you enter your fused stance, you gain all the
                                effects of both stances, including the requirements and restrictions.
                            </p>
                            <p>
                                You can't fuse stances with fundamentally incompatible requirements or restrictions
                                (such as Mountain Stance and Crane Stance, which both require using only one type of
                                Strike).
                            </p>
                        </div>
                        <div id="choiceArea" class="list-item">
                            <div class="newrow list-item">
                                <div class="newrow list-item left-aligned">
                                    <strong>Stance name</strong>
                                    <input type="text" maxLength="30"
                                        [ngModel]="fuseStanceData.getValue('name')"
                                        (input)="fuseStanceData.setValue('name', $event)" (blur)="onFuseStanceNameChange()">
                                </div>
                            </div>
                            <div class="list-item transparent">
                                <header class="sectionHeader">Fused Stances</header>
                            </div>
                            <ng-container
                                *ngFor="let stance of fuseStanceAvailableStances(fuseStanceContent.levelNumber, fuseStanceData); trackBy:trackers.trackByIndex;">
                                <ng-template #FuseStanceChoiceDetailsPopover>
                                    <header class="spellHeader">{{stance.activity.name}}
                                        <app-actionIcons *ngIf="stance.activity.actions"
                                            [actionString]="stance.activity.actions" />{{(stance.activity.activationType) ?
                                        stance.activity.activationType
                                        : ""}}
                                    </header>
                                    <app-activity [creature]="creatureTypesEnum.Character" [activity]=stance.activity
                                        [allowActivate]=false />
                                </ng-template>
                                <div class="list-item gridicon-fullsizebox"
                                    [ngClass]="{'unavailable':stance.reason, 'selected':fuseStanceData.valueAsStringArray('stances')?.includes(stance.activity.name)}">
                                    <input type="checkbox" class="character-choice" id="{{stance.activity.name}}"
                                        [checked]="fuseStanceData.valueAsStringArray('stances')?.includes(stance.activity.name)"
                                        [disabled]="(finished || stance.reason) && !fuseStanceData.valueAsStringArray('stances')?.includes(stance.activity.name)"
                                        (change)="onFuseStanceStanceChange(fuseStanceData, stance.activity.name, $event)">
                                    <div class="gridicon-fullsizebox" triggers="click"
                                        [ngbPopover]="FuseStanceChoiceDetailsPopover">
                                        <app-gridIcon [title]="stance.activity.name"
                                            [superTitle]="stance.restricted ? 'icon-ra ra-bird-claw' : ''" />
                                        <header class="sectionHeader">
                                            <span>{{stance.activity.name}}&nbsp;</span>
                                            <cite *ngIf="stance.restricted" class="trait"
                                                [ngbPopover]="'While in this stance, you can only use the stance\'s special attack. You can only add one stance of this type to the fused stance.'"
                                                [openDelay]=100>
                                                Attack restrictions
                                            </cite>
                                            <cite *ngIf="stance.reason" class="problem">
                                                {{stance.reason}}
                                            </cite>
                                        </header>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!--Additional Heritages content-->
            <ng-container *ngIf="activeSpecialChoiceShown('additionalheritages') as additionalHeritagesContent">
                <ng-container
                    *ngFor="let heritageGain of additionalHeritagesAvailable(additionalHeritagesContent.levelNumber); trackBy:trackers.trackByIndex">
                    <ng-container
                        *ngFor="let heritageIndex of [additionalHeritageIndex(heritageGain.source, additionalHeritagesContent.levelNumber)]; trackBy:trackers.trackByIndex">
                        <ng-container *ngIf="heritageGain.source === additionalHeritagesContent.choice.source">
                            <div class="newrow list-item padding-8 center-aligned">
                                <header class="box-header sectionHeader">
                                    Heritage
                                    ({{heritageGain.source}}){{(character.class.additionalHeritages[heritageIndex].name)
                                    ? ": "+ character.class.additionalHeritages[heritageIndex].name : ""}}
                                </header>
                            </div>
                            <div id="choiceArea" class="list-item">
                                <ng-container
                                    *ngFor="let heritage of availableHeritages('', heritageGain.ancestry, heritageIndex); trackBy:trackers.trackByIndex;">
                                    <ng-container
                                        *ngFor="let selected of [heritage.name === character.class.additionalHeritages[heritageIndex].name]; trackBy:trackers.trackByIndex;">
                                        <ng-container
                                            *ngFor="let checked of [selected || !!doesCharacterHaveHeritage(heritage.name)]; trackBy:trackers.trackByIndex;">
                                            <ng-container
                                                *ngFor="let disabled of [!selected && (checked || !!character.class.additionalHeritages[heritageIndex].name)]; trackBy:trackers.trackByIndex;">
                                                <ng-template #HeritageChoiceDetailsTemplate>
                                                    <header class="spellHeader">{{heritage.name}}</header>
                                                    <div *ngIf="!disabled && !heritage.subTypes.length"
                                                        class="button newrow no-animation"
                                                        [ngClass]="{'fancy-button':selected, 'disabled':disabled}">
                                                        <label>
                                                            <input type="checkbox"
                                                                hidden
                                                                [checked]="checked" [disabled]="disabled"
                                                                (change)="onAdditionalHeritageChange(heritage, $event, heritageIndex)">{{checked ?
                                                            "Remove" : "Choose"}}
                                                        </label>
                                                    </div>
                                                    <div class="newrow left-aligned tags">
                                                        <app-trait
                                                            *ngFor="let trait of heritage.traits; trackBy:trackers.trackByIndex;"
                                                            [name]="trait" [trait]="traitFromName(trait)" />
                                                    </div>
                                                    <div *ngIf="heritage.sourceBook" class="newrow left-aligned">
                                                        <strong>Source</strong>
                                                        <i>{{heritage.sourceBook}}</i>
                                                    </div>
                                                    <div class="newrow left-aligned">
                                                        <ng-container
                                                            *ngFor="let desc of heritage.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
                                                            <app-description class="newrow" [text]="desc" />
                                                        </ng-container>
                                                    </div>
                                                    <ng-container
                                                        *ngFor="let activityName of heritage.gainActivities; trackBy:trackers.trackByIndex;">
                                                        <div *ngIf="activityFromName(activityName) as activity"
                                                            class="newrow left-aligned">
                                                            <header class="spellHeader left-aligned">
                                                                {{activity.name}}
                                                                <app-actionIcons *ngIf="activity.actions"
                                                                    [actionString]="activity.actions" />
                                                                {{(activity.activationType) ? activity.activationType :
                                                                ""}}
                                                            </header>
                                                            <app-activity class="newrow" [activity]=activity
                                                                [allowActivate]=false />
                                                        </div>
                                                    </ng-container>
                                                    <!--Subheritages-->
                                                    <ng-container
                                                        *ngFor="let subheritage of heritage.subTypes; trackBy:trackers.trackByIndex;">
                                                        <ng-container
                                                            *ngFor="let subheritageSelected of [subheritage.name === character.class.additionalHeritages[heritageIndex].name]; trackBy:trackers.trackByIndex;">
                                                            <ng-container
                                                                *ngFor="let subheritageChecked of [subheritageSelected || doesCharacterHaveHeritage(heritage.name)]; trackBy:trackers.trackByIndex;">
                                                                <ng-container
                                                                    *ngFor="let subheritageDisabled of [!subheritageSelected && (subheritageChecked || character.class.additionalHeritages[heritageIndex].name)]; trackBy:trackers.trackByIndex;">
                                                                    <div class="list-item"
                                                                        [ngClass]="{'selected':subheritageSelected}">
                                                                        <div class="gridicon-fullsizebox lower">
                                                                            <input type="checkbox"
                                                                                class="character-choice" id="{{subheritage.name}}"
                                                                                [checked]="subheritageChecked"
                                                                                [disabled]="subheritageDisabled"
                                                                                (change)="onAdditionalHeritageChange(subheritage, $event, heritageIndex)">
                                                                            <label for="{{subheritage.name}}">
                                                                                <strong>{{subheritage.subType}}</strong>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                    <!--End Subheritages-->
                                                </ng-template>
                                                <div class="list-item gridicon-fullsizebox"
                                                    [ngClass]="{'selected':selected}">
                                                    <input *ngIf="!heritage.subTypes.length" type="checkbox"
                                                        class="character-choice" id="{{heritage.name}}"
                                                        [checked]="checked"
                                                        [disabled]="disabled" (change)="onAdditionalHeritageChange(heritage, $event, heritageIndex)">
                                                    <button *ngIf="heritage.subTypes.length" class="character-choice"
                                                        (click)="HeritageChoiceDetailsPopover.toggle()">+</button>
                                                    <div #HeritageChoiceDetailsPopover="ngbPopover"
                                                        class="gridicon-fullsizebox"
                                                        triggers="click" [ngbPopover]="HeritageChoiceDetailsTemplate">
                                                        <app-gridIcon [title]="heritage.name"
                                                            [detail]="heritage.traits.includes('Rare') ? 'Rare' : (heritage.traits.includes('Uncommon') ? 'Uncommon' : '')" />
                                                        <header class="sectionHeader">
                                                            <span>{{heritage.name}}&nbsp;</span>
                                                            <ng-container
                                                                *ngFor="let trait of ['Rare', 'Uncommon']; trackBy:trackers.trackByIndex">
                                                                <app-trait *ngIf="heritage.traits.includes(trait)"
                                                                    [name]="trait" [trait]="traitFromName(trait)" />
                                                            </ng-container>
                                                        </header>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!--Syncretism choice content-->
            <ng-container *ngIf="activeSpecialChoiceShown('syncretism') as syncretismContent">
                <ng-container
                    *ngFor="let syncretismData of syncretismData(syncretismContent.levelNumber); trackBy:trackers.trackByIndex">
                    <ng-container
                        *ngFor="let finishedDeity of [syncretismData.valueAsString('deity')]; trackBy:trackers.trackByIndex">
                        <div class="newrow list-item padding-8 center-aligned">
                            <header class="box-header sectionHeader">
                                Second Deity{{finishedDeity ? ": " + finishedDeity : ""}}
                            </header>
                        </div>
                        <!-- Filter -->
                        <div class="list-item lower">
                            <strong>Find (in Name, Description or Domains)</strong>
                            <span class="hlist">
                                <input id="deityWordFilter" type=text [(ngModel)]="deityWordFilter" />
                            </span>
                        </div>
                        <div id="choiceArea" class="list-item">
                            <ng-container
                                *ngFor="let deity of availableDeities('', true, syncretismContent.levelNumber); trackBy:trackers.trackByIndex;">
                                <ng-container
                                    *ngFor="let checked of [[finishedDeity, character.class.deity].includes(deity.name)]; trackBy:trackers.trackByIndex">
                                    <ng-container
                                        *ngFor="let disabled of [deity.name === character.class.deity || (!!finishedDeity && (deity.name !== finishedDeity))]; trackBy:trackers.trackByIndex">
                                        <ng-template #DeityChoiceDetailsTemplate>
                                            <header class="spellHeader">{{deity.name}}</header>
                                            <div *ngIf="!disabled" class="button newrow no-animation"
                                                [ngClass]="{'fancy-button':checked, 'disabled':disabled}">
                                                <label>
                                                    <input type="checkbox"
                                                        hidden
                                                        [checked]="checked" [disabled]="disabled" (change)="onSyncretismDeityChange(syncretismData, deity, $event)">
                                                    {{checked ? "Remove" : "Choose"}}
                                                </label>
                                            </div>
                                            <header *ngIf="deity.nickname" class="subsectionHeader">{{deity.nickname}}
                                            </header>
                                            <div *ngIf="!deity.alternateDomains.length"
                                                class="newrow left-aligned problem">
                                                This deity has no alternate domains and will not grant a domain spell to
                                                a cloistered cleric.
                                            </div>
                                            <div *ngIf="!deity.favoredWeapon.length"
                                                class="newrow left-aligned problem">
                                                This deity has no favored weapon and will not grant one a warpriest.
                                            </div>
                                            <div *ngIf="deity.sourceBook" class="newrow left-aligned">
                                                <strong>Source</strong>
                                                <i>{{deity.sourceBook}}</i>
                                            </div>
                                            <ng-container
                                                *ngFor="let desc of deity.desc.split('\n\n'); trackBy:trackers.trackByIndex;">
                                                <app-description class="newrow" [text]="desc" />
                                            </ng-container>
                                            <div *ngIf="deity.edicts" class="newrow left-aligned gap-text">
                                                <strong>Edicts</strong>
                                                {{deity.edicts.join(", ")}}
                                            </div>
                                            <div *ngIf="deity.anathema" class="newrow left-aligned gap-text">
                                                <strong>Anathema</strong>
                                                {{deity.anathema.join(", ")}}
                                            </div>
                                            <div *ngIf="deity.areasOfConcern" class="newrow left-aligned gap-text">
                                                <strong>Areas of Concern</strong>
                                                {{deity.areasOfConcern}}
                                            </div>
                                            <div *ngIf="deity.followerAlignments.length"
                                                class="newrow left-aligned gap-text">
                                                <strong>Follower Alignments</strong>
                                                {{deity.followerAlignments.join(", ")}}
                                            </div>
                                            <div *ngIf="deity.pantheonMembers.length"
                                                class="newrow left-aligned gap-text">
                                                <strong>Pantheon Members</strong>
                                                {{deity.pantheonMembers.join(", ")}}
                                            </div>
                                            <div *ngIf="deity.divineAbility.length"
                                                class="newrow left-aligned gap-text">
                                                <strong>Divine Ability</strong>
                                                {{deity.divineAbility.join(" or ")}}
                                            </div>
                                            <div *ngIf="deity.divineFont.length" class="newrow left-aligned gap-text">
                                                <strong>Divine Font</strong>
                                                {{deity.divineFont.join(" or ")}}
                                            </div>
                                            <div *ngIf="deity.divineSkill.length" class="newrow left-aligned gap-text">
                                                <strong>Divine Skill</strong>
                                                {{deity.divineSkill.join(" or ")}}
                                            </div>
                                            <div *ngIf="deity.favoredWeapon.length"
                                                class="newrow left-aligned gap-text">
                                                <strong>Favored Weapon</strong>
                                                {{deity.favoredWeapon.join(" or ")}}
                                            </div>
                                            <div *ngIf="deity.domains.length" class="newrow left-aligned gap-text">
                                                <strong>Domains</strong>
                                                {{deity.domains.join(", ")}}
                                            </div>
                                            <div *ngIf="deity.alternateDomains.length"
                                                class="newrow left-aligned gap-text">
                                                <strong>Alternate Domains</strong>
                                                {{deity.alternateDomains.join(", ")}}
                                            </div>
                                            <ng-container *ngIf="deity.clericSpells.length">
                                                <div class="newrow left-aligned">
                                                    <strong>
                                                        Cleric Spells
                                                    </strong>
                                                </div>
                                                <div *ngFor="let spell of deity.clericSpells; trackBy:trackers.trackByIndex;"
                                                    class="list-item lower left-aligned">
                                                    <strong>
                                                        Level {{spell.level}}
                                                    </strong>
                                                    {{spell.name}}
                                                    {{(spell.restrictionDesc ? "("+spell.restrictionDesc+")" : "")}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                        <div class="list-item gridicon-fullsizebox" [ngClass]="{'selected':checked}">
                                            <input type="checkbox" class="character-choice" id="{{deity.name}}"
                                                [checked]="checked"
                                                [disabled]="disabled" (change)="onSyncretismDeityChange(syncretismData, deity, $event)">
                                            <div class="gridicon-fullsizebox" triggers="click"
                                                [ngbPopover]="DeityChoiceDetailsTemplate">
                                                <app-gridIcon [title]="deity.name" [detail]="deity.alignment" />
                                                <header class="sectionHeader">
                                                    <span>
                                                        {{deity.name}}
                                                    </span>
                                                    <cite *ngIf="deity.alignment" class="trait">
                                                        {{deity.alignment}}
                                                    </cite>
                                                    <cite *ngIf="!deity.alternateDomains.length" class="">
                                                        No alternate domains
                                                    </cite>
                                                    <cite *ngIf="!deity.favoredWeapon.length" class="">
                                                        No favored weapon
                                                    </cite>
                                                </header>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!--Licenses content-->
            <ng-container *ngIf="shownList()==='licenses'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader">
                        Licenses
                    </header>
                </div>
                <div id="choiceArea" style="font-size: 1em;">
                    <app-licenses class="list-item" />
                </div>
            </ng-container>
            <!--About content-->
            <ng-container *ngIf="shownList()==='about'">
                <div class="newrow list-item padding-8 center-aligned">
                    <header class="box-header sectionHeader" style="margin-bottom: 0">
                        Pathfinder Excessive Character Sheet
                    </header>
                    <div class="newrow lower center-aligned">Version {{versionString}}</div>
                </div>
                <div id="choiceArea">
                    <app-about class="list-item" />
                </div>
            </ng-container>
        </div>
    </div>
</div>
