<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div id="top-bar" class="newrow">
    <header class="mainHeader mobile-hide" [ngbTooltip]="'Pathfinder Excessive Character Sheet'">P.E.C.S.</header>
    <button *ngIf="stillLoading" class="fancy-button loading">{{loadingButtonTitle}}</button>
    <ng-container *ngIf="!stillLoading">
        <button [ngClass]="{'fancy-button':characterMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.CharacterMenu)">
            <i class="ra ra-player"></i>
            <div class="mobile-hide">
                {{character.name || "Character"}}&nbsp;
            </div>
            <i class="bi-gear"></i>
        </button>
        <button *ngIf="isCompanionAvailable()"
            [ngClass]="{'fancy-button':companionMenuState==='out'}" (click)="toggleMenu(MenuNamesEnum.CompanionMenu)">
            <i class="ra ra-wolf-howl"></i>
            <div class="mobile-hide">
                {{companion.name || "Animal Companion"}}
            </div>
        </button>
        <button *ngIf="isFamiliarAvailable()" [ngClass]="{'fancy-button':familiarMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.FamiliarMenu)">
            <i class="ra ra-raven"></i>
            <div class="mobile-hide">
                {{familiar.name || "Familiar"}}
            </div>
        </button>
        <button [ngClass]="{'fancy-button':itemsMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.ItemsMenu)">Items</button>
        <button [ngClass]="{'fancy-button':craftingMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.CraftingMenu)">Crafting</button>
        <button *ngIf="hasAnySpells()" [ngClass]="{'fancy-button':spellsMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.SpellsMenu)">Spellbook</button>
        <button [ngClass]="{'fancy-button':spellLibraryMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.SpellLibraryMenu)">Spell Library</button>
        <button [ngClass]="{'fancy-button':conditionsMenuState==='out'}"
            (click)="toggleMenu(MenuNamesEnum.ConditionsMenu)">Conditions</button>
        <button title="Dice" [ngClass]="{'fancy-button':diceMenuState==='out'}" [ngbTooltip]="'Dice'"
            (click)="toggleMenu(MenuNamesEnum.DiceMenu)">
            <app-diceIcons-D20 />
        </button>
        <span *ngIf="areSavegamesInitializing || isLoggingIn" style="flex-grow: initial"
            [ngbTooltip]="'Connecting to database...'">
            <button class="fancy-button loading" disabled><i class="bi-file-earmark-arrow-up"></i></button>
        </span>
        <button *ngIf="isGMMode" class="inactive-button no-animation"
            [ngbTooltip]="'In GM mode, the character can\'t be saved and can\'t send effects to other players.'">
            GM Mode
        </button>
        <ng-container *ngIf="!areSavegamesInitializing && !isLoggingIn">
            <span *ngIf="cannotLogin" style="flex-grow: initial" [ngbTooltip]="'Database connection failed'">
                <button class="fancy-button" disabled><i class="bi-file-earmark-break"></i></button>
            </span>
            <ng-container *ngIf="!cannotLogin">
                <span *ngIf="!isLoggedIn" style="flex-grow: initial" [ngbTooltip]="'Not logged in'">
                    <button class="fancy-button" disabled><i class="bi-file-earmark-lock"></i></button>
                </span>
                <span *ngIf="!hasDBConnectionURL" style="flex-grow: initial" [ngbTooltip]="'No database configured'">
                    <button class="fancy-button" disabled><i class="bi-file-earmark-x"></i></button>
                </span>
                <ng-container *ngIf="hasDBConnectionURL && isLoggedIn">
                    <span *ngIf="!savegames" style="flex-grow: initial" [ngbTooltip]="'Database connection failed'">
                        <button class="fancy-button" disabled><i class="bi-file-earmark-break"></i></button>
                    </span>
                    <ng-container *ngIf="savegames() && { isBlankCharacter: isBlankCharacter() } as blankCharacterStatus">
                        <ng-container *ngIf="!isGMMode">
                            <span *ngIf="blankCharacterStatus.isBlankCharacter" style="flex-grow: initial"
                                [ngbTooltip]="'No changes to character yet.'">
                                <button class="fancy-button" disabled><i class="bi-file-earmark-arrow-up"></i></button>
                            </span>
                            <span *ngIf="!blankCharacterStatus.isBlankCharacter" style="flex-grow: initial"
                                [ngbTooltip]="'Save Character'">
                                <button class="fancy-button" (click)="save()"><i
                                        class="bi-file-earmark-arrow-up"></i></button>
                            </span>
                        </ng-container>
                        <span *ngIf="!blankCharacterStatus.isBlankCharacter && !isManualMode" style="flex-grow: initial"
                            [ngbTooltip]="character.partyName ? 'Check for new effects' : 'You can only receive effects in a party.'">
                            <button class="fancy-button" [disabled]="!character.partyName" (click)="getMessages()">
                                <i class="ra ra-player-thunder-struck"></i>
                                <ng-container *ngIf="!modalOpen && newMessagesFromService() as newMessages">
                                    <div *ngIf="newMessages.length" class="window-button-container">
                                        {{newMessages.length}}</div>
                                </ng-container>
                            </button>
                        </span>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <span style="flex-grow: initial" [ngbTooltip]="'Refresh all'"><button class="fancy-button"
                (click)="refreshAll()"><i class="ra ra-cycle"></i></button></span>
    </ng-container>
</div>
<!-- New Effects Dialog -->
<ng-template #NewMessagesModal let-modal>
    <div class="modal-header">
        <header class="sectionHeader modal-title" id="modal-title">{{cachedNewMessages.length}} New
            Effect{{cachedNewMessages.length
            > 1 ? "s" : ""}}
        </header>
        <button type="button" class="close" aria-label="close" ngbAutofocus (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body vlist">
        <p>Select all effects you want to apply. All other effects will be discarded.</p>
        <div class="gridicon-fullsizebox">
            <input id="messagesSelectAll" class="character-choice" type="checkbox"
                [checked]="areAllMessagesSelected()" (change)="onSelectAllMessages($event)">
            <label for="messagesSelectAll" style="font-size: 1.5em;">
                <strong>Select all</strong>
            </label>
        </div>
        <div class="fullsize-scroll-box vlist" style="max-height: 50vh;">
            <ng-container *ngFor="let message of cachedNewMessages; trackBy:trackers.trackByIndex">
                <ng-container *ngIf="creatureFromMessage(message) as creature">
                    <ng-container *ngIf="messageSenderName(message) as sender">
                        <ng-container *ngIf="creature && (message.gainCondition.length || message.offeredItem.length)">
                            <div class="list-item gridicon-fullsizebox">
                                <input class="character-choice" type="checkbox" id="message{{message.id}}"
                                    [(ngModel)]="message.selected">
                                <label for="message{{message.id}}">
                                    <div class="newrow left-aligned">
                                        <i *ngIf="message.offeredItem.length" class="ra ra-sword"></i>
                                        <i *ngIf="message.gainCondition.length" class="ra ra-splash"></i>
                                        <i *ngIf="message.gainCondition.length && message.activateCondition"
                                            class="bi-play-circle"></i>
                                        <i *ngIf="message.gainCondition.length && !message.activateCondition"
                                            class="bi-stop-circle"></i>
                                        <i *ngIf="creature.type==='Character'" class="ra ra-player"
                                            style="line-height: 1.5;"></i>
                                        <i *ngIf="creature.type==='Companion'" class="ra ra-wolf-howl"
                                            style="line-height: 1.5;"></i>
                                        <i *ngIf="creature.type==='Familiar'" class="ra ra-raven"
                                            style="line-height: 1.5;"></i>
                                        <ng-container *ngIf="message.gainCondition.length">
                                            <strong>
                                                {{message.gainCondition[0].name}}{{message.gainCondition[0].choice ?
                                                ": " + message.gainCondition[0].choice : ""}}
                                            </strong>
                                            {{[durationDescription(message.gainCondition[0].duration)]}}
                                        </ng-container>
                                        <ng-container *ngIf="message.offeredItem.length">
                                            <strong>
                                                {{message.itemAmount !== 1 ? message.itemAmount + " " :
                                                ""}}{{message.offeredItem[0].name}}
                                            </strong>
                                            {{itemMessageIncludedAmount(message)}}
                                        </ng-container>
                                    </div>
                                    <div class="newrow lower">
                                        Sent{{sender ? " by " + sender : ""}} at {{message.time}}
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="newrow left-aligned">
            <input id="checkMessagesAutomatically" type="checkbox"
                [(ngModel)]="character.settings.checkMessagesAutomatically">
            <label for="checkMessagesAutomatically">&nbsp;Keep checking automatically (can be turned off in
                settings)</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cancel click')">Cancel</button>
        <button type="button" class="btn btn-primary" style="background-color: rgb(var(--accent));"
            (click)="modal.close('Apply click')">Apply selected effects</button>
    </div>
</ng-template>
<ng-template #LoginModal let-modal>
    <div class="modal-header">
        <header class="sectionHeader modal-title" id="modal-title" autocomplete="off">Password Required
        </header>
    </div>
    <form>
        <div class="modal-body vlist">
            <p *ngIf="loggedOutMessage as loggedOutMessageText">{{loggedOutMessageText}}</p>
            <p>Please enter the password for this PECS server:</p>
            <input placeholder="Password" title="Password" name="password" id="passwordInput" type="password"
                class="fullwidth" [(ngModel)]="password">
            <p *ngIf="passwordFailed" class="problem">The password was not correct.</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" style="background-color: rgb(var(--accent));"
                (click)="modal.close('OK click')">Login</button>
        </div>
    </form>
</ng-template>
